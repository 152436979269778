import React from "react";

import { StatusPills } from "components/Invoice/components/StatusPills";

import { Contract } from "../Contract";
import { CustomerPlan } from "../CustomerPlan";
import { printDateRange, printDate, toDayjs, Dayjs } from "lib/date";
import {
  InvoiceNameFragment,
  InvoiceTypeFragment,
  InvoiceRoutePathFragment,
  InvoiceStatusFragment,
  InvoiceDateFragment,
} from "./fragments.graphql";

export type {
  InvoiceNameFragment as NameFragment,
  InvoiceTypeFragment as TypeFragment,
  InvoiceRoutePathFragment as RoutePathFragment,
  InvoiceStatusFragment as StatusFragment,
  InvoiceDateFragment as DateFragment,
} from "./fragments.graphql";

export function printType(invoice: InvoiceTypeFragment): string {
  switch (invoice.__typename) {
    case "AdvanceInvoice":
      return "Advance";
    case "AdHocPlanInvoice":
      return "AdHoc";
    case "ArrearsInvoice":
      return "Arrears";
    case "CorrectionInvoice":
      return "Correction";
    case "CreditPurchaseInvoice":
      return "Credit purchase";
    case "ContractScheduledInvoice":
      return "Scheduled";
    case "ContractProServiceInvoice":
      return "Professional service";
    case "ContractUsageInvoice":
      return "Usage";
    case "AdhocContractUsageInvoice":
      return "ContractAdHoc";
    case "ContractPostpaidTrueupInvoice":
      return "Postpaid trueup";
    case "SeatPurchaseInvoice":
      return "Seat purchase";
    case "ContractRefundInvoice":
      return "Refund";
    case "ParentInvoice":
      return "Parent";
  }
}

export function getContractOrPlanName(invoice: InvoiceNameFragment): string {
  switch (invoice.__typename) {
    case "AdvanceInvoice":
    case "ArrearsInvoice":
      return invoice.customer_plan?.Plan.name ?? "None";
    case "ContractScheduledInvoice":
    case "ContractProServiceInvoice":
    case "ContractUsageInvoice":
    case "AdhocContractUsageInvoice":
    case "ContractRefundInvoice":
    case "ContractPostpaidTrueupInvoice":
      return Contract.getName(invoice.contract);
    case "AdHocPlanInvoice":
      return invoice.plan?.name ?? "None";
    case "CorrectionInvoice":
    case "CreditPurchaseInvoice":
    case "SeatPurchaseInvoice": // TODO(ekaragiannis) - this should prob be in advanced invoice
    case "ParentInvoice":
      return `None - ${printType(invoice)}`;
  }
}

export function getRoutePath(invoice: InvoiceRoutePathFragment): string {
  switch (invoice.__typename) {
    case "ContractPostpaidTrueupInvoice":
    case "ContractRefundInvoice":
    case "ContractScheduledInvoice":
    case "ContractProServiceInvoice":
    case "ContractUsageInvoice":
    case "AdhocContractUsageInvoice":
      return `${Contract.getRoutePath(invoice.contract)}/invoices/${
        invoice.id
      }`;
    case "AdHocPlanInvoice":
      if (!invoice.customer) {
        throw new Error(
          `unable to get routePath for invoice of type ${invoice.__typename} with no customer`,
        );
      }
      return `/customers/${invoice.customer.id}/plans/${invoice.plan.id}/invoices/${invoice.id}`;
    case "ArrearsInvoice":
    case "AdvanceInvoice":
      if (!invoice.customer_plan) {
        throw new Error(
          `unable to get routePath for invoice of type ${invoice.__typename} with no customer_plan`,
        );
      }

      return `${CustomerPlan.getRoutePath(invoice.customer_plan)}/invoices/${
        invoice.id
      }`;
    case "CorrectionInvoice":
      const originalInvoice = invoice.correction_record.original_invoice;
      if (originalInvoice.__typename === "ArrearsInvoice") {
        if (!originalInvoice.customer_plan) {
          throw new Error(
            `unable to get routePath for invoice of type ${invoice.__typename} with no customer_plan`,
          );
        }

        return `${CustomerPlan.getRoutePath(
          originalInvoice.customer_plan,
        )}/invoices/${invoice.id}`;
      }

      if (
        originalInvoice.__typename === "ContractScheduledInvoice" ||
        originalInvoice.__typename === "ContractUsageInvoice"
      ) {
        return `${Contract.getRoutePath(originalInvoice.contract)}/invoices/${
          invoice.id
        }`;
      }

      throw new Error(
        `unable to get routePath for invoice of type ${invoice.__typename}`,
      );
    case "CreditPurchaseInvoice":
    case "ParentInvoice":
    case "SeatPurchaseInvoice":
      throw new Error(
        `unable to get routePath for invoice of type ${invoice.__typename}`,
      );
  }
}

export function renderStatusPills(
  invoice: InvoiceStatusFragment,
  { light = true, right = true } = {},
) {
  return (
    <StatusPills
      invoice={invoice}
      light={light}
      right={right}
      className="flex-col gap-4"
    />
  );
}

export function renderDate(invoice: InvoiceDateFragment, now: Dayjs): string {
  switch (invoice.__typename) {
    case "AdHocPlanInvoice":
    case "ArrearsInvoice":
    case "ContractUsageInvoice":
    case "AdhocContractUsageInvoice":
      return printDateRange(
        now,
        toDayjs(invoice.inclusive_start_date),
        toDayjs(invoice.exclusive_end_date),
      );

    case "AdvanceInvoice":
    case "ContractPostpaidTrueupInvoice":
    case "ContractRefundInvoice":
    case "ContractScheduledInvoice":
    case "ContractProServiceInvoice":
    case "CorrectionInvoice":
    case "CreditPurchaseInvoice":
    case "ParentInvoice":
    case "SeatPurchaseInvoice":
      return printDate(toDayjs(invoice.issued_at));
  }
}
