import type React from "react";

import type { CreditInputProps } from "components/Input";
import type {
  InputProps,
  NumericInputProps,
  Select,
  Toggle,
  DateInput,
} from "design-system";
import { Form } from "./Form";
import type { FormController } from "../FormController";
import { TextAreaProps } from "design-system/components/Input";

type SelectProps = React.ComponentProps<typeof Select>;
type ToggleProps = React.ComponentProps<typeof Toggle>;
type DateInputProps = React.ComponentProps<typeof DateInput>;

interface CommonInputProps {
  error?: string;
  onFocus: () => void;
  onBlur: () => void;
}

export type PropOptions<
  S extends Form.Shape,
  Base extends object,
  Overridden extends keyof Base,
> = Omit<Base, Overridden | keyof CommonInputProps> & {
  map?: (update: Form.Update<S>) => Form.Update<S>;
};

export interface PropCreators<S extends Form.Shape> {
  /**
   * Get the props needed for an `<Input />` component that is bound to
   * a specific field in the form.
   */
  Input(
    key: Form.Key<S>,
    options: PropOptions<S, InputProps, "value" | "onChange">,
  ): InputProps;

  /**
   * Get the props needed for an `<TextArea />` component that is bound to
   * a specific field in the form.
   */
  TextArea(
    key: Form.Key<S>,
    options: PropOptions<S, TextAreaProps, "value" | "onChange">,
  ): TextAreaProps;

  /**
   * Get the props needed for a `<NumericInput />` component that is bound to
   * a specific field in the form.
   */
  NumericInput(
    key: Form.Key<S>,
    options: PropOptions<S, NumericInputProps, "value" | "onChange">,
  ): NumericInputProps;

  /**
   * Get the props needed for a `<CreditInput />` component that is bound to
   * a specific field in the form.
   */
  CreditInput(
    key: Form.Key<S>,
    options: PropOptions<S, CreditInputProps, "initialValue" | "onChange">,
  ): CreditInputProps;

  /**
   * Get the props needed for an `<DateTimeInput />` component that is bound to
   * a specific field in the form.
   */
  DateInput(
    key: Form.Key<S>,
    options: PropOptions<
      S,
      DateInputProps,
      "value" | "onChange" | "minDate" | "maxDate" | "isUTC"
    > & {
      minDate?: Date | string;
      maxDate?: Date | string;
    },
  ): DateInputProps;

  /**
   * Get the props needed for a `<Select />` component that is bound to
   * a specific field in the form.
   */
  Select<K extends Form.Key<S>>(
    key: K,
    options: PropOptions<
      S,
      SelectProps & { multiSelect: false },
      "value" | "multiSelect" | "onChange" | "options"
    > & {
      options:
        | Array<{
            label: string;
            value: Form.Value<S, K> | "";
            hidden?: boolean;
          }>
        | Array<{
            label: string;
            options: Array<{
              label: string;
              value: Form.Value<S, K> | "";
              hidden?: boolean;
            }>;
          }>;
    },
  ): SelectProps & { multiSelect: false };

  /**
   * Get the props needed for a `<Select />` component (where multiSelect is true)
   * that is bound to a specific field in the form.
   */
  MultiSelect<K extends Form.Key<S>>(
    key: K,
    options: PropOptions<
      S,
      SelectProps & { multiSelect: true },
      "value" | "onChange" | "options" | "multiSelect"
    > & {
      options: Array<{
        label: string;
        value: Form.Value<S, K>[number];
      }>;
    },
  ): SelectProps & { multiSelect: true };

  /**
   * Get the props needed for a `<Toggle />` component that is bound to
   * a specific field in the form.
   */
  Toggle(
    key: Form.Key<S>,
    options: Omit<ToggleProps, "checked" | "onChange"> & {
      map?: (update: Form.Update<S>) => Form.Update<S>;
    },
  ): ToggleProps;
}

export namespace PropCreators {
  export function create<S extends Form.Shape>(
    ctrl: FormController<S>,
  ): PropCreators<S> {
    function getCommonInputProps(key: Form.Key<S>): CommonInputProps {
      const field = ctrl.state.fields[key];
      return {
        error: Form.Field.showsErrors(field) ? field.error : undefined,
        onFocus: () => ctrl.onFocus(key),
        onBlur: () => ctrl.onBlur(key),
      };
    }

    return {
      Input(key, options) {
        const { map, ...others } = options;
        return {
          ...others,
          ...getCommonInputProps(key),
          value: `${ctrl.state.fields[key].value ?? ""}`,
          onChange: (value) => {
            const update = { [key]: value } as Form.Update<S>;
            ctrl.update(map ? map(update) : update);
          },
        };
      },

      TextArea(key, options) {
        const { map, ...others } = options;
        return {
          ...others,
          ...getCommonInputProps(key),
          value: `${ctrl.state.fields[key].value ?? ""}`,
          onChange: (value) => {
            const update = { [key]: value } as Form.Update<S>;
            ctrl.update(map ? map(update) : update);
          },
        };
      },

      NumericInput(key, options) {
        const { map, ...others } = options;

        return {
          ...others,
          value: ctrl.state.fields[key].value,
          onChange: (value) => {
            const update = { [key]: value } as Form.Update<S>;
            ctrl.update(map ? map(update) : update);
          },
          ...getCommonInputProps(key),
        };
      },

      CreditInput(key, options) {
        const { map, ...others } = options;
        return {
          ...others,
          initialValue: ctrl.state.fields[key].value,
          onChange: (value) => {
            const update = { [key]: value } as Form.Update<S>;
            ctrl.update(map ? map(update) : update);
          },
          ...getCommonInputProps(key),
        };
      },

      DateInput(key, options) {
        const { map, ...others } = options;
        const value = ctrl.state.fields[key].value;
        return {
          ...others,
          value: value ? new Date(value) : undefined,
          minDate:
            typeof options.minDate === "string"
              ? new Date(options.minDate)
              : options.minDate,
          maxDate:
            typeof options.maxDate === "string"
              ? new Date(options.maxDate)
              : options.maxDate,
          isUTC: true,
          onChange: (value) => {
            const update = { [key]: value.toISOString() } as Form.Update<S>;
            ctrl.update(map ? map(update) : update);
          },
          ...getCommonInputProps(key),
        };
      },

      /**
       * Get the props needed for a `<Select />` component that is bound to
       * a specific field in the form.
       */
      Select(key, opts) {
        const { map, options: originalOptions, ...others } = opts;
        const options = [];
        for (const option of originalOptions) {
          if ("options" in option) {
            options.push({
              label: option.label,
              options: option.options
                .filter((o) => !o.hidden)
                .map((o) => ({
                  label: o.label,
                  // since we technically allow any key to be specified the
                  // values might not be strings, so we cast to ensure they are
                  value: `${o.value}`,
                })),
            });
          } else {
            if (!option.hidden) {
              options.push({
                label: option.label,
                // since we technically allow any key to be specified the
                // values might not be strings, so we cast to ensure they are
                value: `${option.value}`,
              });
            }
          }
        }
        return {
          ...others,
          options,
          value: ctrl.state.fields[key].value || "",
          multiSelect: false,
          onChange: (value) => {
            const update = {
              [key]: value === "" ? undefined : value,
            } as Form.Update<S>;
            ctrl.update(map ? map(update) : update);
          },
          ...getCommonInputProps(key),
        };
      },

      /**
       * Get the props needed for a `<Select />` component (where multiSelect is true)
       * that is bound to a specific field in the form.
       */
      MultiSelect(key, opts) {
        const { map, options, ...others } = opts;
        return {
          ...others,
          options: options.map((option) => ({
            label: option.label,
            // since we technically allow any key to be specified the
            // values might not be strings, so we cast to ensure they are
            value: `${option.value}`,
          })),
          value: ctrl.state.fields[key].value ?? [],
          multiSelect: true,
          onChange: (value) => {
            const update = {
              [key]: value.length === 0 ? undefined : value,
            } as Form.Update<S>;
            ctrl.update(map ? map(update) : update);
          },
          ...getCommonInputProps(key),
        };
      },

      Toggle(key, options) {
        const { map, ...others } = options;
        return {
          ...others,
          checked: !!ctrl.state.fields[key].value,
          onChange: (value) => {
            const update = { [key]: value } as Form.Update<S>;
            ctrl.update(map ? map(update) : update);
          },
        };
      },
    };
  }
}
