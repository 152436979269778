import React from "react";
import classNames from "classnames";
import { Headline, Body } from "design-system";
import styles from "./index.module.less";
import { DocumentNode } from "graphql";
import { Icon, IconName } from "tenaissance/components/Icon";
import { GatedButton, GatedIconButton } from "../GatedButton";

type Props = {
  title: string;
  subtitle?: React.ReactNode;
  icon?: IconName;
  image?: React.ReactNode;
  buttonText?: string;
  buttonIcon?: IconName;
  onClick?: () => void;
  buttonRoutePath?: string;
  buttonAuthDoc?: DocumentNode;
};

export const EmptyState: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.icon, {
          [styles.empty]: !props.image && !props.icon,
        })}
      >
        {props.icon && <Icon icon={props.icon} />}
        {props.image && props.image}
      </div>
      <Headline level={4}>{props.title}</Headline>
      {props.subtitle && (
        <Body level={1} className={styles.bodyText}>
          {props.subtitle}
        </Body>
      )}
      {props.buttonText ? (
        <GatedButton
          className={styles.button}
          doc={props.buttonAuthDoc}
          onClick={props.onClick}
          text={props.buttonText}
          theme="primary"
          leadingIcon={props.buttonIcon}
          linkTo={props.buttonRoutePath}
        />
      ) : props.buttonIcon ? (
        <GatedIconButton
          className={styles.button}
          doc={props.buttonAuthDoc}
          onClick={props.onClick}
          theme="primary"
          icon={props.buttonIcon}
          linkTo={props.buttonRoutePath}
        />
      ) : null}
    </div>
  );
};
