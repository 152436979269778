import React, { useState } from "react";

import styles from "./index.module.css";
import { Popover, PopoverPosition } from "react-tiny-popover";

type TooltipProps = React.PropsWithChildren<{
  content: React.ReactNode;
  inline?: boolean;
  disabled?: boolean;
  position?: PopoverPosition[];
}>;

export const Tooltip: React.FC<TooltipProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return props.content ? (
    <Popover
      positions={props.position ?? ["top", "left", "right", "bottom"]}
      isOpen={isOpen && !props.disabled}
      content={<TooltipContent>{props.content}</TooltipContent>}
    >
      <div
        className={props.inline ? styles.inline_container : styles.container}
        onMouseOver={() => setIsOpen(true)}
        onMouseOut={() => setIsOpen(false)}
      >
        {props.children}
      </div>
    </Popover>
  ) : (
    <>{props.children}</>
  );
};

export const TooltipContent: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <div className={styles.tooltip}>{children}</div>;
};
