import React, { ReactElement } from "react";
import classnames from "classnames";
import { Subtitle } from "design-system";
import { EmbeddableDashboardContext } from "lib/embeddableDashboardContext";

interface ProductContainerProps extends React.PropsWithChildren {
  className?: string;
  title: ReactElement;
  groupKey: string | null;
  button?: ReactElement | false;
  theme?: "contracts";
}

export const ProductContainer: React.FC<ProductContainerProps> = ({
  className,
  title,
  groupKey,
  children,
  button,
  theme,
}) => {
  const { getGroupDisplayName } = EmbeddableDashboardContext.useContainer();
  return (
    <div
      className={classnames(
        "mb-12 mt-4 rounded-medium border border-grey-100 bg-background",
        className,
      )}
    >
      <div
        className={classnames(
          "flex items-center justify-between border-b border-b-gray-100 bg-primary-50 px-12 py-8",
          button && "pr-0",
          theme === "contracts" && "bg-gray-50",
        )}
      >
        <div className="flex items-center text-primary-700">{title}</div>
        <div className="flex items-center gap-12">
          {groupKey ? (
            <div>
              <Subtitle level={4}>
                Group by:{" "}
                <code className="bg-grey-50">
                  {getGroupDisplayName(groupKey)}
                </code>
              </Subtitle>
            </div>
          ) : null}
          {button}
        </div>
      </div>
      {children}
    </div>
  );
};
