import React from "react";

import { InternalLink } from "components/Typography";

import { useNow } from "lib/date";
import { Commit } from "../lib/Commit";

export const CommitReference: React.FC<
  Commit.NameFragment & Commit.RoutePathFragment
> = (commit) => {
  const now = useNow();

  return (
    <InternalLink routePath={Commit.getRoutePath(commit)}>
      {Commit.getName(commit, now)}
    </InternalLink>
  );
};
