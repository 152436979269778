import { ReactComponent as activity } from "./activity.svg";
import { ReactComponent as activityHeart } from "./activityHeart.svg";
import { ReactComponent as airplay } from "./airplay.svg";
import { ReactComponent as airpods } from "./airpods.svg";
import { ReactComponent as alarmClock } from "./alarmClock.svg";
import { ReactComponent as alarmClockCheck } from "./alarmClockCheck.svg";
import { ReactComponent as alarmClockMinus } from "./alarmClockMinus.svg";
import { ReactComponent as alarmClockOff } from "./alarmClockOff.svg";
import { ReactComponent as alarmClockPlus } from "./alarmClockPlus.svg";
import { ReactComponent as alertCircle } from "./alertCircle.svg";
import { ReactComponent as alertHexagon } from "./alertHexagon.svg";
import { ReactComponent as alertOctagon } from "./alertOctagon.svg";
import { ReactComponent as alertSquare } from "./alertSquare.svg";
import { ReactComponent as alertTriangle } from "./alertTriangle.svg";
import { ReactComponent as alignBottom01 } from "./alignBottom01.svg";
import { ReactComponent as alignBottom02 } from "./alignBottom02.svg";
import { ReactComponent as alignCenter } from "./alignCenter.svg";
import { ReactComponent as alignHorizontalCentre01 } from "./alignHorizontalCentre01.svg";
import { ReactComponent as alignHorizontalCentre02 } from "./alignHorizontalCentre02.svg";
import { ReactComponent as alignJustify } from "./alignJustify.svg";
import { ReactComponent as alignLeft } from "./alignLeft.svg";
import { ReactComponent as alignLeft01 } from "./alignLeft01.svg";
import { ReactComponent as alignLeft02 } from "./alignLeft02.svg";
import { ReactComponent as alignRight } from "./alignRight.svg";
import { ReactComponent as alignRight01 } from "./alignRight01.svg";
import { ReactComponent as alignRight02 } from "./alignRight02.svg";
import { ReactComponent as alignTop01 } from "./alignTop01.svg";
import { ReactComponent as alignTop02 } from "./alignTop02.svg";
import { ReactComponent as alignVerticalCenter01 } from "./alignVerticalCenter01.svg";
import { ReactComponent as alignVerticalCenter02 } from "./alignVerticalCenter02.svg";
import { ReactComponent as anchor } from "./anchor.svg";
import { ReactComponent as annotation } from "./annotation.svg";
import { ReactComponent as annotationAlert } from "./annotationAlert.svg";
import { ReactComponent as annotationCheck } from "./annotationCheck.svg";
import { ReactComponent as annotationDots } from "./annotationDots.svg";
import { ReactComponent as annotationHeart } from "./annotationHeart.svg";
import { ReactComponent as annotationInfo } from "./annotationInfo.svg";
import { ReactComponent as annotationPlus } from "./annotationPlus.svg";
import { ReactComponent as annotationQuestion } from "./annotationQuestion.svg";
import { ReactComponent as annotationX } from "./annotationX.svg";
import { ReactComponent as announcement01 } from "./announcement01.svg";
import { ReactComponent as announcement02 } from "./announcement02.svg";
import { ReactComponent as announcement03 } from "./announcement03.svg";
import { ReactComponent as archive } from "./archive.svg";
import { ReactComponent as arrowBlockDown } from "./arrowBlockDown.svg";
import { ReactComponent as arrowBlockLeft } from "./arrowBlockLeft.svg";
import { ReactComponent as arrowBlockRight } from "./arrowBlockRight.svg";
import { ReactComponent as arrowBlockUp } from "./arrowBlockUp.svg";
import { ReactComponent as arrowCircleBrokenDown } from "./arrowCircleBrokenDown.svg";
import { ReactComponent as arrowCircleBrokenDownLeft } from "./arrowCircleBrokenDownLeft.svg";
import { ReactComponent as arrowCircleBrokenDownRight } from "./arrowCircleBrokenDownRight.svg";
import { ReactComponent as arrowCircleBrokenLeft } from "./arrowCircleBrokenLeft.svg";
import { ReactComponent as arrowCircleBrokenRight } from "./arrowCircleBrokenRight.svg";
import { ReactComponent as arrowCircleBrokenUp } from "./arrowCircleBrokenUp.svg";
import { ReactComponent as arrowCircleBrokenUpLeft } from "./arrowCircleBrokenUpLeft.svg";
import { ReactComponent as arrowCircleBrokenUpRight } from "./arrowCircleBrokenUpRight.svg";
import { ReactComponent as arrowCircleDown } from "./arrowCircleDown.svg";
import { ReactComponent as arrowCircleDownLeft } from "./arrowCircleDownLeft.svg";
import { ReactComponent as arrowCircleDownRight } from "./arrowCircleDownRight.svg";
import { ReactComponent as arrowCircleLeft } from "./arrowCircleLeft.svg";
import { ReactComponent as arrowCircleRight } from "./arrowCircleRight.svg";
import { ReactComponent as arrowCircleUp } from "./arrowCircleUp.svg";
import { ReactComponent as arrowCircleUpLeft } from "./arrowCircleUpLeft.svg";
import { ReactComponent as arrowCircleUpRight } from "./arrowCircleUpRight.svg";
import { ReactComponent as arrowDown } from "./arrowDown.svg";
import { ReactComponent as arrowDownLeft } from "./arrowDownLeft.svg";
import { ReactComponent as arrowDownRight } from "./arrowDownRight.svg";
import { ReactComponent as arrowLeft } from "./arrowLeft.svg";
import { ReactComponent as arrowNarrowDown } from "./arrowNarrowDown.svg";
import { ReactComponent as arrowNarrowDownLeft } from "./arrowNarrowDownLeft.svg";
import { ReactComponent as arrowNarrowDownRight } from "./arrowNarrowDownRight.svg";
import { ReactComponent as arrowNarrowLeft } from "./arrowNarrowLeft.svg";
import { ReactComponent as arrowNarrowRight } from "./arrowNarrowRight.svg";
import { ReactComponent as arrowNarrowUp } from "./arrowNarrowUp.svg";
import { ReactComponent as arrowNarrowUpLeft } from "./arrowNarrowUpLeft.svg";
import { ReactComponent as arrowNarrowUpRight } from "./arrowNarrowUpRight.svg";
import { ReactComponent as arrowRight } from "./arrowRight.svg";
import { ReactComponent as arrowSquareDown } from "./arrowSquareDown.svg";
import { ReactComponent as arrowSquareDownLeft } from "./arrowSquareDownLeft.svg";
import { ReactComponent as arrowSquareDownRight } from "./arrowSquareDownRight.svg";
import { ReactComponent as arrowSquareLeft } from "./arrowSquareLeft.svg";
import { ReactComponent as arrowSquareRight } from "./arrowSquareRight.svg";
import { ReactComponent as arrowSquareUp } from "./arrowSquareUp.svg";
import { ReactComponent as arrowSquareUpLeft } from "./arrowSquareUpLeft.svg";
import { ReactComponent as arrowSquareUpRight } from "./arrowSquareUpRight.svg";
import { ReactComponent as arrowUp } from "./arrowUp.svg";
import { ReactComponent as arrowUpLeft } from "./arrowUpLeft.svg";
import { ReactComponent as arrowUpRight } from "./arrowUpRight.svg";
import { ReactComponent as arrowsDown } from "./arrowsDown.svg";
import { ReactComponent as arrowsLeft } from "./arrowsLeft.svg";
import { ReactComponent as arrowsRight } from "./arrowsRight.svg";
import { ReactComponent as arrowsTriangle } from "./arrowsTriangle.svg";
import { ReactComponent as arrowsUp } from "./arrowsUp.svg";
import { ReactComponent as asterisk01 } from "./asterisk01.svg";
import { ReactComponent as asterisk02 } from "./asterisk02.svg";
import { ReactComponent as atSign } from "./atSign.svg";
import { ReactComponent as atom01 } from "./atom01.svg";
import { ReactComponent as atom02 } from "./atom02.svg";
import { ReactComponent as attachment01 } from "./attachment01.svg";
import { ReactComponent as attachment02 } from "./attachment02.svg";
import { ReactComponent as award01 } from "./award01.svg";
import { ReactComponent as award02 } from "./award02.svg";
import { ReactComponent as award03 } from "./award03.svg";
import { ReactComponent as award04 } from "./award04.svg";
import { ReactComponent as award05 } from "./award05.svg";
import { ReactComponent as backpack } from "./backpack.svg";
import { ReactComponent as bank } from "./bank.svg";
import { ReactComponent as bankNote01 } from "./bankNote01.svg";
import { ReactComponent as bankNote02 } from "./bankNote02.svg";
import { ReactComponent as bankNote03 } from "./bankNote03.svg";
import { ReactComponent as barChart01 } from "./barChart01.svg";
import { ReactComponent as barChart02 } from "./barChart02.svg";
import { ReactComponent as barChart03 } from "./barChart03.svg";
import { ReactComponent as barChart04 } from "./barChart04.svg";
import { ReactComponent as barChart05 } from "./barChart05.svg";
import { ReactComponent as barChart06 } from "./barChart06.svg";
import { ReactComponent as barChart07 } from "./barChart07.svg";
import { ReactComponent as barChart08 } from "./barChart08.svg";
import { ReactComponent as barChart09 } from "./barChart09.svg";
import { ReactComponent as barChart10 } from "./barChart10.svg";
import { ReactComponent as barChart11 } from "./barChart11.svg";
import { ReactComponent as barChart12 } from "./barChart12.svg";
import { ReactComponent as barChartCircle01 } from "./barChartCircle01.svg";
import { ReactComponent as barChartCircle02 } from "./barChartCircle02.svg";
import { ReactComponent as barChartCircle03 } from "./barChartCircle03.svg";
import { ReactComponent as barChartSquare01 } from "./barChartSquare01.svg";
import { ReactComponent as barChartSquare02 } from "./barChartSquare02.svg";
import { ReactComponent as barChartSquare03 } from "./barChartSquare03.svg";
import { ReactComponent as barChartSquareDown } from "./barChartSquareDown.svg";
import { ReactComponent as barChartSquareMinus } from "./barChartSquareMinus.svg";
import { ReactComponent as barChartSquarePlus } from "./barChartSquarePlus.svg";
import { ReactComponent as barChartSquareUp } from "./barChartSquareUp.svg";
import { ReactComponent as barLineChart } from "./barLineChart.svg";
import { ReactComponent as batteryCharging01 } from "./batteryCharging01.svg";
import { ReactComponent as batteryCharging02 } from "./batteryCharging02.svg";
import { ReactComponent as batteryEmpty } from "./batteryEmpty.svg";
import { ReactComponent as batteryFull } from "./batteryFull.svg";
import { ReactComponent as batteryLow } from "./batteryLow.svg";
import { ReactComponent as batteryMid } from "./batteryMid.svg";
import { ReactComponent as beaker01 } from "./beaker01.svg";
import { ReactComponent as beaker02 } from "./beaker02.svg";
import { ReactComponent as bell01 } from "./bell01.svg";
import { ReactComponent as bell02 } from "./bell02.svg";
import { ReactComponent as bell03 } from "./bell03.svg";
import { ReactComponent as bell04 } from "./bell04.svg";
import { ReactComponent as bellMinus } from "./bellMinus.svg";
import { ReactComponent as bellOff01 } from "./bellOff01.svg";
import { ReactComponent as bellOff02 } from "./bellOff02.svg";
import { ReactComponent as bellOff03 } from "./bellOff03.svg";
import { ReactComponent as bellPlus } from "./bellPlus.svg";
import { ReactComponent as bellRinging01 } from "./bellRinging01.svg";
import { ReactComponent as bellRinging02 } from "./bellRinging02.svg";
import { ReactComponent as bellRinging03 } from "./bellRinging03.svg";
import { ReactComponent as bellRinging04 } from "./bellRinging04.svg";
import { ReactComponent as bezierCurve01 } from "./bezierCurve01.svg";
import { ReactComponent as bezierCurve02 } from "./bezierCurve02.svg";
import { ReactComponent as bezierCurve03 } from "./bezierCurve03.svg";
import { ReactComponent as bluetoothConnect } from "./bluetoothConnect.svg";
import { ReactComponent as bluetoothOff } from "./bluetoothOff.svg";
import { ReactComponent as bluetoothOn } from "./bluetoothOn.svg";
import { ReactComponent as bluetoothSignal } from "./bluetoothSignal.svg";
import { ReactComponent as bold01 } from "./bold01.svg";
import { ReactComponent as bold02 } from "./bold02.svg";
import { ReactComponent as boldSquare } from "./boldSquare.svg";
import { ReactComponent as bookClosed } from "./bookClosed.svg";
import { ReactComponent as bookOpen01 } from "./bookOpen01.svg";
import { ReactComponent as bookOpen02 } from "./bookOpen02.svg";
import { ReactComponent as bookmark } from "./bookmark.svg";
import { ReactComponent as bookmarkAdd } from "./bookmarkAdd.svg";
import { ReactComponent as bookmarkCheck } from "./bookmarkCheck.svg";
import { ReactComponent as bookmarkMinus } from "./bookmarkMinus.svg";
import { ReactComponent as bookmarkX } from "./bookmarkX.svg";
import { ReactComponent as box } from "./box.svg";
import { ReactComponent as brackets } from "./brackets.svg";
import { ReactComponent as bracketsCheck } from "./bracketsCheck.svg";
import { ReactComponent as bracketsEllipses } from "./bracketsEllipses.svg";
import { ReactComponent as bracketsMinus } from "./bracketsMinus.svg";
import { ReactComponent as bracketsPlus } from "./bracketsPlus.svg";
import { ReactComponent as bracketsSlash } from "./bracketsSlash.svg";
import { ReactComponent as bracketsX } from "./bracketsX.svg";
import { ReactComponent as briefcase01 } from "./briefcase01.svg";
import { ReactComponent as briefcase02 } from "./briefcase02.svg";
import { ReactComponent as browser } from "./browser.svg";
import { ReactComponent as brush01 } from "./brush01.svg";
import { ReactComponent as brush02 } from "./brush02.svg";
import { ReactComponent as brush03 } from "./brush03.svg";
import { ReactComponent as building01 } from "./building01.svg";
import { ReactComponent as building02 } from "./building02.svg";
import { ReactComponent as building03 } from "./building03.svg";
import { ReactComponent as building04 } from "./building04.svg";
import { ReactComponent as building05 } from "./building05.svg";
import { ReactComponent as building06 } from "./building06.svg";
import { ReactComponent as building07 } from "./building07.svg";
import { ReactComponent as building08 } from "./building08.svg";
import { ReactComponent as bus } from "./bus.svg";
import { ReactComponent as calculator } from "./calculator.svg";
import { ReactComponent as calendar } from "./calendar.svg";
import { ReactComponent as calendarCheck01 } from "./calendarCheck01.svg";
import { ReactComponent as calendarCheck02 } from "./calendarCheck02.svg";
import { ReactComponent as calendarDate } from "./calendarDate.svg";
import { ReactComponent as calendarHeart01 } from "./calendarHeart01.svg";
import { ReactComponent as calendarHeart02 } from "./calendarHeart02.svg";
import { ReactComponent as calendarMinus01 } from "./calendarMinus01.svg";
import { ReactComponent as calendarMinus02 } from "./calendarMinus02.svg";
import { ReactComponent as calendarPlus01 } from "./calendarPlus01.svg";
import { ReactComponent as calendarPlus02 } from "./calendarPlus02.svg";
import { ReactComponent as camera01 } from "./camera01.svg";
import { ReactComponent as camera02 } from "./camera02.svg";
import { ReactComponent as camera03 } from "./camera03.svg";
import { ReactComponent as cameraLens } from "./cameraLens.svg";
import { ReactComponent as cameraOff } from "./cameraOff.svg";
import { ReactComponent as cameraPlus } from "./cameraPlus.svg";
import { ReactComponent as car01 } from "./car01.svg";
import { ReactComponent as car02 } from "./car02.svg";
import { ReactComponent as certificate01 } from "./certificate01.svg";
import { ReactComponent as certificate02 } from "./certificate02.svg";
import { ReactComponent as chartBreakoutCircle } from "./chartBreakoutCircle.svg";
import { ReactComponent as chartBreakoutSquare } from "./chartBreakoutSquare.svg";
import { ReactComponent as check } from "./check.svg";
import { ReactComponent as checkCircle } from "./checkCircle.svg";
import { ReactComponent as checkCircleBroken } from "./checkCircleBroken.svg";
import { ReactComponent as checkDone01 } from "./checkDone01.svg";
import { ReactComponent as checkDone02 } from "./checkDone02.svg";
import { ReactComponent as checkHeart } from "./checkHeart.svg";
import { ReactComponent as checkSquare } from "./checkSquare.svg";
import { ReactComponent as checkSquareBroken } from "./checkSquareBroken.svg";
import { ReactComponent as checkVerified01 } from "./checkVerified01.svg";
import { ReactComponent as checkVerified02 } from "./checkVerified02.svg";
import { ReactComponent as checkVerified03 } from "./checkVerified03.svg";
import { ReactComponent as chevronDown } from "./chevronDown.svg";
import { ReactComponent as chevronDownDouble } from "./chevronDownDouble.svg";
import { ReactComponent as chevronLeft } from "./chevronLeft.svg";
import { ReactComponent as chevronLeftDouble } from "./chevronLeftDouble.svg";
import { ReactComponent as chevronRight } from "./chevronRight.svg";
import { ReactComponent as chevronRightDouble } from "./chevronRightDouble.svg";
import { ReactComponent as chevronSelectorHorizontal } from "./chevronSelectorHorizontal.svg";
import { ReactComponent as chevronSelectorVertical } from "./chevronSelectorVertical.svg";
import { ReactComponent as chevronUp } from "./chevronUp.svg";
import { ReactComponent as chevronUpDouble } from "./chevronUpDouble.svg";
import { ReactComponent as chromeCast } from "./chromeCast.svg";
import { ReactComponent as circle } from "./circle.svg";
import { ReactComponent as circleCut } from "./circleCut.svg";
import { ReactComponent as clapperboard } from "./clapperboard.svg";
import { ReactComponent as clipboard } from "./clipboard.svg";
import { ReactComponent as clipboardAttachment } from "./clipboardAttachment.svg";
import { ReactComponent as clipboardCheck } from "./clipboardCheck.svg";
import { ReactComponent as clipboardDownload } from "./clipboardDownload.svg";
import { ReactComponent as clipboardMinus } from "./clipboardMinus.svg";
import { ReactComponent as clipboardPlus } from "./clipboardPlus.svg";
import { ReactComponent as clipboardX } from "./clipboardX.svg";
import { ReactComponent as clock } from "./clock.svg";
import { ReactComponent as clockCheck } from "./clockCheck.svg";
import { ReactComponent as clockFastForward } from "./clockFastForward.svg";
import { ReactComponent as clockPlus } from "./clockPlus.svg";
import { ReactComponent as clockRefresh } from "./clockRefresh.svg";
import { ReactComponent as clockRewind } from "./clockRewind.svg";
import { ReactComponent as clockSnooze } from "./clockSnooze.svg";
import { ReactComponent as clockStopwatch } from "./clockStopwatch.svg";
import { ReactComponent as cloud01 } from "./cloud01.svg";
import { ReactComponent as cloud02 } from "./cloud02.svg";
import { ReactComponent as cloud03 } from "./cloud03.svg";
import { ReactComponent as cloudBlank01 } from "./cloudBlank01.svg";
import { ReactComponent as cloudBlank02 } from "./cloudBlank02.svg";
import { ReactComponent as cloudLightning } from "./cloudLightning.svg";
import { ReactComponent as cloudMoon } from "./cloudMoon.svg";
import { ReactComponent as cloudOff } from "./cloudOff.svg";
import { ReactComponent as cloudRaining01 } from "./cloudRaining01.svg";
import { ReactComponent as cloudRaining02 } from "./cloudRaining02.svg";
import { ReactComponent as cloudRaining03 } from "./cloudRaining03.svg";
import { ReactComponent as cloudRaining04 } from "./cloudRaining04.svg";
import { ReactComponent as cloudRaining05 } from "./cloudRaining05.svg";
import { ReactComponent as cloudRaining06 } from "./cloudRaining06.svg";
import { ReactComponent as cloudSnowing01 } from "./cloudSnowing01.svg";
import { ReactComponent as cloudSnowing02 } from "./cloudSnowing02.svg";
import { ReactComponent as cloudSun01 } from "./cloudSun01.svg";
import { ReactComponent as cloudSun02 } from "./cloudSun02.svg";
import { ReactComponent as cloudSun03 } from "./cloudSun03.svg";
import { ReactComponent as code01 } from "./code01.svg";
import { ReactComponent as code02 } from "./code02.svg";
import { ReactComponent as codeBrowser } from "./codeBrowser.svg";
import { ReactComponent as codeCircle01 } from "./codeCircle01.svg";
import { ReactComponent as codeCircle02 } from "./codeCircle02.svg";
import { ReactComponent as codeCircle03 } from "./codeCircle03.svg";
import { ReactComponent as codeSnippet01 } from "./codeSnippet01.svg";
import { ReactComponent as codeSnippet02 } from "./codeSnippet02.svg";
import { ReactComponent as codeSquare01 } from "./codeSquare01.svg";
import { ReactComponent as codeSquare02 } from "./codeSquare02.svg";
import { ReactComponent as codepen } from "./codepen.svg";
import { ReactComponent as coins01 } from "./coins01.svg";
import { ReactComponent as coins02 } from "./coins02.svg";
import { ReactComponent as coins03 } from "./coins03.svg";
import { ReactComponent as coins04 } from "./coins04.svg";
import { ReactComponent as coinsHand } from "./coinsHand.svg";
import { ReactComponent as coinsStacked01 } from "./coinsStacked01.svg";
import { ReactComponent as coinsStacked02 } from "./coinsStacked02.svg";
import { ReactComponent as coinsStacked03 } from "./coinsStacked03.svg";
import { ReactComponent as coinsStacked04 } from "./coinsStacked04.svg";
import { ReactComponent as coinsSwap01 } from "./coinsSwap01.svg";
import { ReactComponent as coinsSwap02 } from "./coinsSwap02.svg";
import { ReactComponent as colors } from "./colors.svg";
import { ReactComponent as columns01 } from "./columns01.svg";
import { ReactComponent as columns02 } from "./columns02.svg";
import { ReactComponent as columns03 } from "./columns03.svg";
import { ReactComponent as command } from "./command.svg";
import { ReactComponent as compass } from "./compass.svg";
import { ReactComponent as compass01 } from "./compass01.svg";
import { ReactComponent as compass02 } from "./compass02.svg";
import { ReactComponent as compass03 } from "./compass03.svg";
import { ReactComponent as container } from "./container.svg";
import { ReactComponent as contrast01 } from "./contrast01.svg";
import { ReactComponent as contrast02 } from "./contrast02.svg";
import { ReactComponent as contrast03 } from "./contrast03.svg";
import { ReactComponent as copy01 } from "./copy01.svg";
import { ReactComponent as copy02 } from "./copy02.svg";
import { ReactComponent as copy03 } from "./copy03.svg";
import { ReactComponent as copy04 } from "./copy04.svg";
import { ReactComponent as copy05 } from "./copy05.svg";
import { ReactComponent as copy06 } from "./copy06.svg";
import { ReactComponent as copy07 } from "./copy07.svg";
import { ReactComponent as cornerDownLeft } from "./cornerDownLeft.svg";
import { ReactComponent as cornerDownRight } from "./cornerDownRight.svg";
import { ReactComponent as cornerLeftDown } from "./cornerLeftDown.svg";
import { ReactComponent as cornerLeftUp } from "./cornerLeftUp.svg";
import { ReactComponent as cornerRightDown } from "./cornerRightDown.svg";
import { ReactComponent as cornerRightUp } from "./cornerRightUp.svg";
import { ReactComponent as cornerUpLeft } from "./cornerUpLeft.svg";
import { ReactComponent as cornerUpRight } from "./cornerUpRight.svg";
import { ReactComponent as cpuChip01 } from "./cpuChip01.svg";
import { ReactComponent as cpuChip02 } from "./cpuChip02.svg";
import { ReactComponent as creditCard01 } from "./creditCard01.svg";
import { ReactComponent as creditCard02 } from "./creditCard02.svg";
import { ReactComponent as creditCardCheck } from "./creditCardCheck.svg";
import { ReactComponent as creditCardDown } from "./creditCardDown.svg";
import { ReactComponent as creditCardDownload } from "./creditCardDownload.svg";
import { ReactComponent as creditCardEdit } from "./creditCardEdit.svg";
import { ReactComponent as creditCardLock } from "./creditCardLock.svg";
import { ReactComponent as creditCardMinus } from "./creditCardMinus.svg";
import { ReactComponent as creditCardPlus } from "./creditCardPlus.svg";
import { ReactComponent as creditCardRefresh } from "./creditCardRefresh.svg";
import { ReactComponent as creditCardSearch } from "./creditCardSearch.svg";
import { ReactComponent as creditCardShield } from "./creditCardShield.svg";
import { ReactComponent as creditCardUp } from "./creditCardUp.svg";
import { ReactComponent as creditCardUpload } from "./creditCardUpload.svg";
import { ReactComponent as creditCardX } from "./creditCardX.svg";
import { ReactComponent as crop01 } from "./crop01.svg";
import { ReactComponent as crop02 } from "./crop02.svg";
import { ReactComponent as cryptocurrency01 } from "./cryptocurrency01.svg";
import { ReactComponent as cryptocurrency02 } from "./cryptocurrency02.svg";
import { ReactComponent as cryptocurrency03 } from "./cryptocurrency03.svg";
import { ReactComponent as cryptocurrency04 } from "./cryptocurrency04.svg";
import { ReactComponent as cube01 } from "./cube01.svg";
import { ReactComponent as cube02 } from "./cube02.svg";
import { ReactComponent as cube03 } from "./cube03.svg";
import { ReactComponent as cube04 } from "./cube04.svg";
import { ReactComponent as cubeOutline } from "./cubeOutline.svg";
import { ReactComponent as currencyBitcoin } from "./currencyBitcoin.svg";
import { ReactComponent as currencyBitcoinCircle } from "./currencyBitcoinCircle.svg";
import { ReactComponent as currencyDollar } from "./currencyDollar.svg";
import { ReactComponent as currencyDollarCircle } from "./currencyDollarCircle.svg";
import { ReactComponent as currencyEthereum } from "./currencyEthereum.svg";
import { ReactComponent as currencyEthereumCircle } from "./currencyEthereumCircle.svg";
import { ReactComponent as currencyEuro } from "./currencyEuro.svg";
import { ReactComponent as currencyEuroCircle } from "./currencyEuroCircle.svg";
import { ReactComponent as currencyPound } from "./currencyPound.svg";
import { ReactComponent as currencyPoundCircle } from "./currencyPoundCircle.svg";
import { ReactComponent as currencyRuble } from "./currencyRuble.svg";
import { ReactComponent as currencyRubleCircle } from "./currencyRubleCircle.svg";
import { ReactComponent as currencyRupee } from "./currencyRupee.svg";
import { ReactComponent as currencyRupeeCircle } from "./currencyRupeeCircle.svg";
import { ReactComponent as currencyYen } from "./currencyYen.svg";
import { ReactComponent as currencyYenCircle } from "./currencyYenCircle.svg";
import { ReactComponent as cursor01 } from "./cursor01.svg";
import { ReactComponent as cursor02 } from "./cursor02.svg";
import { ReactComponent as cursor03 } from "./cursor03.svg";
import { ReactComponent as cursor04 } from "./cursor04.svg";
import { ReactComponent as cursorBox } from "./cursorBox.svg";
import { ReactComponent as cursorClick01 } from "./cursorClick01.svg";
import { ReactComponent as cursorClick02 } from "./cursorClick02.svg";
import { ReactComponent as data } from "./data.svg";
import { ReactComponent as database01 } from "./database01.svg";
import { ReactComponent as database02 } from "./database02.svg";
import { ReactComponent as database03 } from "./database03.svg";
import { ReactComponent as dataflow01 } from "./dataflow01.svg";
import { ReactComponent as dataflow02 } from "./dataflow02.svg";
import { ReactComponent as dataflow03 } from "./dataflow03.svg";
import { ReactComponent as dataflow04 } from "./dataflow04.svg";
import { ReactComponent as deleteIcon } from "./delete.svg";
import { ReactComponent as diamond01 } from "./diamond01.svg";
import { ReactComponent as diamond02 } from "./diamond02.svg";
import { ReactComponent as dice1 } from "./dice1.svg";
import { ReactComponent as dice2 } from "./dice2.svg";
import { ReactComponent as dice3 } from "./dice3.svg";
import { ReactComponent as dice4 } from "./dice4.svg";
import { ReactComponent as dice5 } from "./dice5.svg";
import { ReactComponent as dice6 } from "./dice6.svg";
import { ReactComponent as disc01 } from "./disc01.svg";
import { ReactComponent as disc02 } from "./disc02.svg";
import { ReactComponent as distributeSpacingHorizontal } from "./distributeSpacingHorizontal.svg";
import { ReactComponent as distributeSpacingVertical } from "./distributeSpacingVertical.svg";
import { ReactComponent as divide01 } from "./divide01.svg";
import { ReactComponent as divide02 } from "./divide02.svg";
import { ReactComponent as divide03 } from "./divide03.svg";
import { ReactComponent as divider } from "./divider.svg";
import { ReactComponent as dotpoints01 } from "./dotpoints01.svg";
import { ReactComponent as dotpoints02 } from "./dotpoints02.svg";
import { ReactComponent as dotsGrid } from "./dotsGrid.svg";
import { ReactComponent as dotsHorizontal } from "./dotsHorizontal.svg";
import { ReactComponent as dotsVertical } from "./dotsVertical.svg";
import { ReactComponent as download01 } from "./download01.svg";
import { ReactComponent as download02 } from "./download02.svg";
import { ReactComponent as download03 } from "./download03.svg";
import { ReactComponent as download04 } from "./download04.svg";
import { ReactComponent as downloadCloud01 } from "./downloadCloud01.svg";
import { ReactComponent as downloadCloud02 } from "./downloadCloud02.svg";
import { ReactComponent as drop } from "./drop.svg";
import { ReactComponent as droplets01 } from "./droplets01.svg";
import { ReactComponent as droplets02 } from "./droplets02.svg";
import { ReactComponent as droplets03 } from "./droplets03.svg";
import { ReactComponent as dropper } from "./dropper.svg";
import { ReactComponent as edit01 } from "./edit01.svg";
import { ReactComponent as edit02 } from "./edit02.svg";
import { ReactComponent as edit03 } from "./edit03.svg";
import { ReactComponent as edit04 } from "./edit04.svg";
import { ReactComponent as edit05 } from "./edit05.svg";
import { ReactComponent as equal } from "./equal.svg";
import { ReactComponent as equalNot } from "./equalNot.svg";
import { ReactComponent as eraser } from "./eraser.svg";
import { ReactComponent as expand01 } from "./expand01.svg";
import { ReactComponent as expand02 } from "./expand02.svg";
import { ReactComponent as expand03 } from "./expand03.svg";
import { ReactComponent as expand04 } from "./expand04.svg";
import { ReactComponent as expand05 } from "./expand05.svg";
import { ReactComponent as expand06 } from "./expand06.svg";
import { ReactComponent as eye } from "./eye.svg";
import { ReactComponent as eyeOff } from "./eyeOff.svg";
import { ReactComponent as faceContent } from "./faceContent.svg";
import { ReactComponent as faceFrown } from "./faceFrown.svg";
import { ReactComponent as faceHappy } from "./faceHappy.svg";
import { ReactComponent as faceId } from "./faceId.svg";
import { ReactComponent as faceIdSquare } from "./faceIdSquare.svg";
import { ReactComponent as faceNeutral } from "./faceNeutral.svg";
import { ReactComponent as faceSad } from "./faceSad.svg";
import { ReactComponent as faceSmile } from "./faceSmile.svg";
import { ReactComponent as faceWink } from "./faceWink.svg";
import { ReactComponent as fastBackward } from "./fastBackward.svg";
import { ReactComponent as fastForward } from "./fastForward.svg";
import { ReactComponent as feather } from "./feather.svg";
import { ReactComponent as figma } from "./figma.svg";
import { ReactComponent as file01 } from "./file01.svg";
import { ReactComponent as file02 } from "./file02.svg";
import { ReactComponent as file03 } from "./file03.svg";
import { ReactComponent as file04 } from "./file04.svg";
import { ReactComponent as file05 } from "./file05.svg";
import { ReactComponent as file06 } from "./file06.svg";
import { ReactComponent as file07 } from "./file07.svg";
import { ReactComponent as fileAttachment01 } from "./fileAttachment01.svg";
import { ReactComponent as fileAttachment02 } from "./fileAttachment02.svg";
import { ReactComponent as fileAttachment03 } from "./fileAttachment03.svg";
import { ReactComponent as fileAttachment04 } from "./fileAttachment04.svg";
import { ReactComponent as fileAttachment05 } from "./fileAttachment05.svg";
import { ReactComponent as fileCheck01 } from "./fileCheck01.svg";
import { ReactComponent as fileCheck02 } from "./fileCheck02.svg";
import { ReactComponent as fileCheck03 } from "./fileCheck03.svg";
import { ReactComponent as fileCode01 } from "./fileCode01.svg";
import { ReactComponent as fileCode02 } from "./fileCode02.svg";
import { ReactComponent as fileDownload01 } from "./fileDownload01.svg";
import { ReactComponent as fileDownload02 } from "./fileDownload02.svg";
import { ReactComponent as fileDownload03 } from "./fileDownload03.svg";
import { ReactComponent as fileHeart01 } from "./fileHeart01.svg";
import { ReactComponent as fileHeart02 } from "./fileHeart02.svg";
import { ReactComponent as fileHeart03 } from "./fileHeart03.svg";
import { ReactComponent as fileLock01 } from "./fileLock01.svg";
import { ReactComponent as fileLock02 } from "./fileLock02.svg";
import { ReactComponent as fileLock03 } from "./fileLock03.svg";
import { ReactComponent as fileMinus01 } from "./fileMinus01.svg";
import { ReactComponent as fileMinus02 } from "./fileMinus02.svg";
import { ReactComponent as fileMinus03 } from "./fileMinus03.svg";
import { ReactComponent as filePlus01 } from "./filePlus01.svg";
import { ReactComponent as filePlus02 } from "./filePlus02.svg";
import { ReactComponent as filePlus03 } from "./filePlus03.svg";
import { ReactComponent as fileQuestion01 } from "./fileQuestion01.svg";
import { ReactComponent as fileQuestion02 } from "./fileQuestion02.svg";
import { ReactComponent as fileQuestion03 } from "./fileQuestion03.svg";
import { ReactComponent as fileSearch01 } from "./fileSearch01.svg";
import { ReactComponent as fileSearch02 } from "./fileSearch02.svg";
import { ReactComponent as fileSearch03 } from "./fileSearch03.svg";
import { ReactComponent as fileShield01 } from "./fileShield01.svg";
import { ReactComponent as fileShield02 } from "./fileShield02.svg";
import { ReactComponent as fileShield03 } from "./fileShield03.svg";
import { ReactComponent as fileX01 } from "./fileX01.svg";
import { ReactComponent as fileX02 } from "./fileX02.svg";
import { ReactComponent as fileX03 } from "./fileX03.svg";
import { ReactComponent as film01 } from "./film01.svg";
import { ReactComponent as film02 } from "./film02.svg";
import { ReactComponent as film03 } from "./film03.svg";
import { ReactComponent as filterFunnel01 } from "./filterFunnel01.svg";
import { ReactComponent as filterFunnel02 } from "./filterFunnel02.svg";
import { ReactComponent as filterLines } from "./filterLines.svg";
import { ReactComponent as fingerprint01 } from "./fingerprint01.svg";
import { ReactComponent as fingerprint02 } from "./fingerprint02.svg";
import { ReactComponent as fingerprint03 } from "./fingerprint03.svg";
import { ReactComponent as fingerprint04 } from "./fingerprint04.svg";
import { ReactComponent as flag01 } from "./flag01.svg";
import { ReactComponent as flag02 } from "./flag02.svg";
import { ReactComponent as flag03 } from "./flag03.svg";
import { ReactComponent as flag04 } from "./flag04.svg";
import { ReactComponent as flag05 } from "./flag05.svg";
import { ReactComponent as flag06 } from "./flag06.svg";
import { ReactComponent as flash } from "./flash.svg";
import { ReactComponent as flashOff } from "./flashOff.svg";
import { ReactComponent as flexAlignBottom } from "./flexAlignBottom.svg";
import { ReactComponent as flexAlignLeft } from "./flexAlignLeft.svg";
import { ReactComponent as flexAlignRight } from "./flexAlignRight.svg";
import { ReactComponent as flexAlignTop } from "./flexAlignTop.svg";
import { ReactComponent as flipBackward } from "./flipBackward.svg";
import { ReactComponent as flipForward } from "./flipForward.svg";
import { ReactComponent as folder } from "./folder.svg";
import { ReactComponent as folderCheck } from "./folderCheck.svg";
import { ReactComponent as folderClosed } from "./folderClosed.svg";
import { ReactComponent as folderCode } from "./folderCode.svg";
import { ReactComponent as folderDownload } from "./folderDownload.svg";
import { ReactComponent as folderLock } from "./folderLock.svg";
import { ReactComponent as folderMinus } from "./folderMinus.svg";
import { ReactComponent as folderPlus } from "./folderPlus.svg";
import { ReactComponent as folderQuestion } from "./folderQuestion.svg";
import { ReactComponent as folderSearch } from "./folderSearch.svg";
import { ReactComponent as folderShield } from "./folderShield.svg";
import { ReactComponent as folderX } from "./folderX.svg";
import { ReactComponent as framer } from "./framer.svg";
import { ReactComponent as gamingPad01 } from "./gamingPad01.svg";
import { ReactComponent as gamingPad02 } from "./gamingPad02.svg";
import { ReactComponent as gift01 } from "./gift01.svg";
import { ReactComponent as gift02 } from "./gift02.svg";
import { ReactComponent as gitBranch01 } from "./gitBranch01.svg";
import { ReactComponent as gitBranch02 } from "./gitBranch02.svg";
import { ReactComponent as gitCommit } from "./gitCommit.svg";
import { ReactComponent as gitMerge } from "./gitMerge.svg";
import { ReactComponent as gitPullRequest } from "./gitPullRequest.svg";
import { ReactComponent as glasses01 } from "./glasses01.svg";
import { ReactComponent as glasses02 } from "./glasses02.svg";
import { ReactComponent as globe01 } from "./globe01.svg";
import { ReactComponent as globe02 } from "./globe02.svg";
import { ReactComponent as globe03 } from "./globe03.svg";
import { ReactComponent as globe04 } from "./globe04.svg";
import { ReactComponent as globe05 } from "./globe05.svg";
import { ReactComponent as globe06 } from "./globe06.svg";
import { ReactComponent as globeSlated01 } from "./globeSlated01.svg";
import { ReactComponent as globeSlated02 } from "./globeSlated02.svg";
import { ReactComponent as googleChrome } from "./googleChrome.svg";
import { ReactComponent as graduationHat01 } from "./graduationHat01.svg";
import { ReactComponent as graduationHat02 } from "./graduationHat02.svg";
import { ReactComponent as grid01 } from "./grid01.svg";
import { ReactComponent as grid02 } from "./grid02.svg";
import { ReactComponent as grid03 } from "./grid03.svg";
import { ReactComponent as gridDotsBlank } from "./gridDotsBlank.svg";
import { ReactComponent as gridDotsBottom } from "./gridDotsBottom.svg";
import { ReactComponent as gridDotsHorizontalCenter } from "./gridDotsHorizontalCenter.svg";
import { ReactComponent as gridDotsLeft } from "./gridDotsLeft.svg";
import { ReactComponent as gridDotsOuter } from "./gridDotsOuter.svg";
import { ReactComponent as gridDotsRight } from "./gridDotsRight.svg";
import { ReactComponent as gridDotsTop } from "./gridDotsTop.svg";
import { ReactComponent as gridDotsVerticalCenter } from "./gridDotsVerticalCenter.svg";
import { ReactComponent as hand } from "./hand.svg";
import { ReactComponent as hardDrive } from "./hardDrive.svg";
import { ReactComponent as hash01 } from "./hash01.svg";
import { ReactComponent as hash02 } from "./hash02.svg";
import { ReactComponent as heading01 } from "./heading01.svg";
import { ReactComponent as heading02 } from "./heading02.svg";
import { ReactComponent as headingSquare } from "./headingSquare.svg";
import { ReactComponent as headphones01 } from "./headphones01.svg";
import { ReactComponent as headphones02 } from "./headphones02.svg";
import { ReactComponent as heart } from "./heart.svg";
import { ReactComponent as heartCircle } from "./heartCircle.svg";
import { ReactComponent as heartHand } from "./heartHand.svg";
import { ReactComponent as heartHexagon } from "./heartHexagon.svg";
import { ReactComponent as heartOctagon } from "./heartOctagon.svg";
import { ReactComponent as heartRounded } from "./heartRounded.svg";
import { ReactComponent as heartSquare } from "./heartSquare.svg";
import { ReactComponent as hearts } from "./hearts.svg";
import { ReactComponent as helpCircle } from "./helpCircle.svg";
import { ReactComponent as helpHexagon } from "./helpHexagon.svg";
import { ReactComponent as helpOctagon } from "./helpOctagon.svg";
import { ReactComponent as helpSquare } from "./helpSquare.svg";
import { ReactComponent as hexagon01 } from "./hexagon01.svg";
import { ReactComponent as hexagon02 } from "./hexagon02.svg";
import { ReactComponent as home01 } from "./home01.svg";
import { ReactComponent as home02 } from "./home02.svg";
import { ReactComponent as home03 } from "./home03.svg";
import { ReactComponent as home04 } from "./home04.svg";
import { ReactComponent as home05 } from "./home05.svg";
import { ReactComponent as homeLine } from "./homeLine.svg";
import { ReactComponent as homeSmile } from "./homeSmile.svg";
import { ReactComponent as horizontalBarChart01 } from "./horizontalBarChart01.svg";
import { ReactComponent as horizontalBarChart02 } from "./horizontalBarChart02.svg";
import { ReactComponent as horizontalBarChart03 } from "./horizontalBarChart03.svg";
import { ReactComponent as hourglass01 } from "./hourglass01.svg";
import { ReactComponent as hourglass02 } from "./hourglass02.svg";
import { ReactComponent as hourglass03 } from "./hourglass03.svg";
import { ReactComponent as hurricane01 } from "./hurricane01.svg";
import { ReactComponent as hurricane02 } from "./hurricane02.svg";
import { ReactComponent as hurricane03 } from "./hurricane03.svg";
import { ReactComponent as image01 } from "./image01.svg";
import { ReactComponent as image02 } from "./image02.svg";
import { ReactComponent as image03 } from "./image03.svg";
import { ReactComponent as image04 } from "./image04.svg";
import { ReactComponent as image05 } from "./image05.svg";
import { ReactComponent as imageCheck } from "./imageCheck.svg";
import { ReactComponent as imageDown } from "./imageDown.svg";
import { ReactComponent as imageIndentLeft } from "./imageIndentLeft.svg";
import { ReactComponent as imageIndentRight } from "./imageIndentRight.svg";
import { ReactComponent as imageLeft } from "./imageLeft.svg";
import { ReactComponent as imagePlus } from "./imagePlus.svg";
import { ReactComponent as imageRight } from "./imageRight.svg";
import { ReactComponent as imageUp } from "./imageUp.svg";
import { ReactComponent as imageUser } from "./imageUser.svg";
import { ReactComponent as imageUserCheck } from "./imageUserCheck.svg";
import { ReactComponent as imageUserDown } from "./imageUserDown.svg";
import { ReactComponent as imageUserLeft } from "./imageUserLeft.svg";
import { ReactComponent as imageUserPlus } from "./imageUserPlus.svg";
import { ReactComponent as imageUserRight } from "./imageUserRight.svg";
import { ReactComponent as imageUserUp } from "./imageUserUp.svg";
import { ReactComponent as imageUserX } from "./imageUserX.svg";
import { ReactComponent as imageX } from "./imageX.svg";
import { ReactComponent as inbox01 } from "./inbox01.svg";
import { ReactComponent as inbox02 } from "./inbox02.svg";
import { ReactComponent as infinity } from "./infinity.svg";
import { ReactComponent as infoCircle } from "./infoCircle.svg";
import { ReactComponent as infoHexagon } from "./infoHexagon.svg";
import { ReactComponent as infoOctagon } from "./infoOctagon.svg";
import { ReactComponent as infoSquare } from "./infoSquare.svg";
import { ReactComponent as intersectCircle } from "./intersectCircle.svg";
import { ReactComponent as intersectSquare } from "./intersectSquare.svg";
import { ReactComponent as italic01 } from "./italic01.svg";
import { ReactComponent as italic02 } from "./italic02.svg";
import { ReactComponent as italicSquare } from "./italicSquare.svg";
import { ReactComponent as key01 } from "./key01.svg";
import { ReactComponent as key02 } from "./key02.svg";
import { ReactComponent as keyboard01 } from "./keyboard01.svg";
import { ReactComponent as keyboard02 } from "./keyboard02.svg";
import { ReactComponent as laptop01 } from "./laptop01.svg";
import { ReactComponent as laptop02 } from "./laptop02.svg";
import { ReactComponent as layerSingle } from "./layerSingle.svg";
import { ReactComponent as layersThree01 } from "./layersThree01.svg";
import { ReactComponent as layersThree02 } from "./layersThree02.svg";
import { ReactComponent as layersTwo01 } from "./layersTwo01.svg";
import { ReactComponent as layersTwo02 } from "./layersTwo02.svg";
import { ReactComponent as layoutAlt01 } from "./layoutAlt01.svg";
import { ReactComponent as layoutAlt02 } from "./layoutAlt02.svg";
import { ReactComponent as layoutAlt03 } from "./layoutAlt03.svg";
import { ReactComponent as layoutAlt04 } from "./layoutAlt04.svg";
import { ReactComponent as layoutBottom } from "./layoutBottom.svg";
import { ReactComponent as layoutGrid01 } from "./layoutGrid01.svg";
import { ReactComponent as layoutGrid02 } from "./layoutGrid02.svg";
import { ReactComponent as layoutLeft } from "./layoutLeft.svg";
import { ReactComponent as layoutRight } from "./layoutRight.svg";
import { ReactComponent as layoutTop } from "./layoutTop.svg";
import { ReactComponent as leftIndent01 } from "./leftIndent01.svg";
import { ReactComponent as leftIndent02 } from "./leftIndent02.svg";
import { ReactComponent as letterSpacing01 } from "./letterSpacing01.svg";
import { ReactComponent as letterSpacing02 } from "./letterSpacing02.svg";
import { ReactComponent as lifeBuoy01 } from "./lifeBuoy01.svg";
import { ReactComponent as lifeBuoy02 } from "./lifeBuoy02.svg";
import { ReactComponent as lightbulb01 } from "./lightbulb01.svg";
import { ReactComponent as lightbulb02 } from "./lightbulb02.svg";
import { ReactComponent as lightbulb03 } from "./lightbulb03.svg";
import { ReactComponent as lightbulb04 } from "./lightbulb04.svg";
import { ReactComponent as lightbulb05 } from "./lightbulb05.svg";
import { ReactComponent as lightning01 } from "./lightning01.svg";
import { ReactComponent as lightning02 } from "./lightning02.svg";
import { ReactComponent as lineChartDown01 } from "./lineChartDown01.svg";
import { ReactComponent as lineChartDown02 } from "./lineChartDown02.svg";
import { ReactComponent as lineChartDown03 } from "./lineChartDown03.svg";
import { ReactComponent as lineChartDown04 } from "./lineChartDown04.svg";
import { ReactComponent as lineChartDown05 } from "./lineChartDown05.svg";
import { ReactComponent as lineChartUp01 } from "./lineChartUp01.svg";
import { ReactComponent as lineChartUp02 } from "./lineChartUp02.svg";
import { ReactComponent as lineChartUp03 } from "./lineChartUp03.svg";
import { ReactComponent as lineChartUp04 } from "./lineChartUp04.svg";
import { ReactComponent as lineChartUp05 } from "./lineChartUp05.svg";
import { ReactComponent as lineHeight } from "./lineHeight.svg";
import { ReactComponent as link01 } from "./link01.svg";
import { ReactComponent as link02 } from "./link02.svg";
import { ReactComponent as link03 } from "./link03.svg";
import { ReactComponent as link04 } from "./link04.svg";
import { ReactComponent as link05 } from "./link05.svg";
import { ReactComponent as linkBroken01 } from "./linkBroken01.svg";
import { ReactComponent as linkBroken02 } from "./linkBroken02.svg";
import { ReactComponent as linkExternal01 } from "./linkExternal01.svg";
import { ReactComponent as linkExternal02 } from "./linkExternal02.svg";
import { ReactComponent as list } from "./list.svg";
import { ReactComponent as loading01 } from "./loading01.svg";
import { ReactComponent as loading02 } from "./loading02.svg";
import { ReactComponent as loading03 } from "./loading03.svg";
import { ReactComponent as lock01 } from "./lock01.svg";
import { ReactComponent as lock02 } from "./lock02.svg";
import { ReactComponent as lock03 } from "./lock03.svg";
import { ReactComponent as lock04 } from "./lock04.svg";
import { ReactComponent as lockKeyholeCircle } from "./lockKeyholeCircle.svg";
import { ReactComponent as lockKeyholeSquare } from "./lockKeyholeSquare.svg";
import { ReactComponent as lockUnlocked01 } from "./lockUnlocked01.svg";
import { ReactComponent as lockUnlocked02 } from "./lockUnlocked02.svg";
import { ReactComponent as lockUnlocked03 } from "./lockUnlocked03.svg";
import { ReactComponent as lockUnlocked04 } from "./lockUnlocked04.svg";
import { ReactComponent as logIn01 } from "./logIn01.svg";
import { ReactComponent as logIn02 } from "./logIn02.svg";
import { ReactComponent as logIn03 } from "./logIn03.svg";
import { ReactComponent as logIn04 } from "./logIn04.svg";
import { ReactComponent as logOut01 } from "./logOut01.svg";
import { ReactComponent as logOut02 } from "./logOut02.svg";
import { ReactComponent as logOut03 } from "./logOut03.svg";
import { ReactComponent as logOut04 } from "./logOut04.svg";
import { ReactComponent as luggage01 } from "./luggage01.svg";
import { ReactComponent as luggage02 } from "./luggage02.svg";
import { ReactComponent as luggage03 } from "./luggage03.svg";
import { ReactComponent as magicWand01 } from "./magicWand01.svg";
import { ReactComponent as magicWand02 } from "./magicWand02.svg";
import { ReactComponent as mail01 } from "./mail01.svg";
import { ReactComponent as mail02 } from "./mail02.svg";
import { ReactComponent as mail03 } from "./mail03.svg";
import { ReactComponent as mail04 } from "./mail04.svg";
import { ReactComponent as mail05 } from "./mail05.svg";
import { ReactComponent as map01 } from "./map01.svg";
import { ReactComponent as map02 } from "./map02.svg";
import { ReactComponent as mark } from "./mark.svg";
import { ReactComponent as markerPin01 } from "./markerPin01.svg";
import { ReactComponent as markerPin02 } from "./markerPin02.svg";
import { ReactComponent as markerPin03 } from "./markerPin03.svg";
import { ReactComponent as markerPin04 } from "./markerPin04.svg";
import { ReactComponent as markerPin05 } from "./markerPin05.svg";
import { ReactComponent as markerPin06 } from "./markerPin06.svg";
import { ReactComponent as maximize01 } from "./maximize01.svg";
import { ReactComponent as maximize02 } from "./maximize02.svg";
import { ReactComponent as medicalCircle } from "./medicalCircle.svg";
import { ReactComponent as medicalCross } from "./medicalCross.svg";
import { ReactComponent as medicalSquare } from "./medicalSquare.svg";
import { ReactComponent as menu01 } from "./menu01.svg";
import { ReactComponent as menu02 } from "./menu02.svg";
import { ReactComponent as menu03 } from "./menu03.svg";
import { ReactComponent as menu04 } from "./menu04.svg";
import { ReactComponent as menu05 } from "./menu05.svg";
import { ReactComponent as messageAlertCircle } from "./messageAlertCircle.svg";
import { ReactComponent as messageAlertSquare } from "./messageAlertSquare.svg";
import { ReactComponent as messageChatCircle } from "./messageChatCircle.svg";
import { ReactComponent as messageChatSquare } from "./messageChatSquare.svg";
import { ReactComponent as messageCheckCircle } from "./messageCheckCircle.svg";
import { ReactComponent as messageCheckSquare } from "./messageCheckSquare.svg";
import { ReactComponent as messageCircle01 } from "./messageCircle01.svg";
import { ReactComponent as messageCircle02 } from "./messageCircle02.svg";
import { ReactComponent as messageDotsCircle } from "./messageDotsCircle.svg";
import { ReactComponent as messageDotsSquare } from "./messageDotsSquare.svg";
import { ReactComponent as messageHeartCircle } from "./messageHeartCircle.svg";
import { ReactComponent as messageHeartSquare } from "./messageHeartSquare.svg";
import { ReactComponent as messageNotificationCircle } from "./messageNotificationCircle.svg";
import { ReactComponent as messageNotificationSquare } from "./messageNotificationSquare.svg";
import { ReactComponent as messagePlusCircle } from "./messagePlusCircle.svg";
import { ReactComponent as messagePlusSquare } from "./messagePlusSquare.svg";
import { ReactComponent as messageQuestionCircle } from "./messageQuestionCircle.svg";
import { ReactComponent as messageQuestionSquare } from "./messageQuestionSquare.svg";
import { ReactComponent as messageSmileCircle } from "./messageSmileCircle.svg";
import { ReactComponent as messageSmileSquare } from "./messageSmileSquare.svg";
import { ReactComponent as messageSquare01 } from "./messageSquare01.svg";
import { ReactComponent as messageSquare02 } from "./messageSquare02.svg";
import { ReactComponent as messageTextCircle01 } from "./messageTextCircle01.svg";
import { ReactComponent as messageTextCircle02 } from "./messageTextCircle02.svg";
import { ReactComponent as messageTextSquare01 } from "./messageTextSquare01.svg";
import { ReactComponent as messageTextSquare02 } from "./messageTextSquare02.svg";
import { ReactComponent as messageXCircle } from "./messageXCircle.svg";
import { ReactComponent as messageXSquare } from "./messageXSquare.svg";
import { ReactComponent as microphone01 } from "./microphone01.svg";
import { ReactComponent as microphone02 } from "./microphone02.svg";
import { ReactComponent as microphoneOff01 } from "./microphoneOff01.svg";
import { ReactComponent as microphoneOff02 } from "./microphoneOff02.svg";
import { ReactComponent as microscope } from "./microscope.svg";
import { ReactComponent as minimize01 } from "./minimize01.svg";
import { ReactComponent as minimize02 } from "./minimize02.svg";
import { ReactComponent as minus } from "./minus.svg";
import { ReactComponent as minusCircle } from "./minusCircle.svg";
import { ReactComponent as minusSquare } from "./minusSquare.svg";
import { ReactComponent as modem01 } from "./modem01.svg";
import { ReactComponent as modem02 } from "./modem02.svg";
import { ReactComponent as monitor01 } from "./monitor01.svg";
import { ReactComponent as monitor02 } from "./monitor02.svg";
import { ReactComponent as monitor03 } from "./monitor03.svg";
import { ReactComponent as monitor04 } from "./monitor04.svg";
import { ReactComponent as monitor05 } from "./monitor05.svg";
import { ReactComponent as moon01 } from "./moon01.svg";
import { ReactComponent as moon02 } from "./moon02.svg";
import { ReactComponent as moonEclipse } from "./moonEclipse.svg";
import { ReactComponent as moonStar } from "./moonStar.svg";
import { ReactComponent as mouse } from "./mouse.svg";
import { ReactComponent as move } from "./move.svg";
import { ReactComponent as musicNote01 } from "./musicNote01.svg";
import { ReactComponent as musicNote02 } from "./musicNote02.svg";
import { ReactComponent as musicNotePlus } from "./musicNotePlus.svg";
import { ReactComponent as navigationPointer01 } from "./navigationPointer01.svg";
import { ReactComponent as navigationPointer02 } from "./navigationPointer02.svg";
import { ReactComponent as navigationPointerOff01 } from "./navigationPointerOff01.svg";
import { ReactComponent as navigationPointerOff02 } from "./navigationPointerOff02.svg";
import { ReactComponent as notificationBox } from "./notificationBox.svg";
import { ReactComponent as notificationMessage } from "./notificationMessage.svg";
import { ReactComponent as notificationText } from "./notificationText.svg";
import { ReactComponent as octagon } from "./octagon.svg";
import { ReactComponent as packageIcon } from "./package.svg";
import { ReactComponent as packageCheck } from "./packageCheck.svg";
import { ReactComponent as packageMinus } from "./packageMinus.svg";
import { ReactComponent as packagePlus } from "./packagePlus.svg";
import { ReactComponent as packageSearch } from "./packageSearch.svg";
import { ReactComponent as packageX } from "./packageX.svg";
import { ReactComponent as paint } from "./paint.svg";
import { ReactComponent as paintPour } from "./paintPour.svg";
import { ReactComponent as palette } from "./palette.svg";
import { ReactComponent as paperclip } from "./paperclip.svg";
import { ReactComponent as paragraphSpacing } from "./paragraphSpacing.svg";
import { ReactComponent as paragraphWrap } from "./paragraphWrap.svg";
import { ReactComponent as passcode } from "./passcode.svg";
import { ReactComponent as passcodeLock } from "./passcodeLock.svg";
import { ReactComponent as passport } from "./passport.svg";
import { ReactComponent as pauseCircle } from "./pauseCircle.svg";
import { ReactComponent as pauseSquare } from "./pauseSquare.svg";
import { ReactComponent as penTool01 } from "./penTool01.svg";
import { ReactComponent as penTool02 } from "./penTool02.svg";
import { ReactComponent as penToolMinus } from "./penToolMinus.svg";
import { ReactComponent as penToolPlus } from "./penToolPlus.svg";
import { ReactComponent as pencil01 } from "./pencil01.svg";
import { ReactComponent as pencil02 } from "./pencil02.svg";
import { ReactComponent as pencilLine } from "./pencilLine.svg";
import { ReactComponent as pentagon } from "./pentagon.svg";
import { ReactComponent as percent01 } from "./percent01.svg";
import { ReactComponent as percent02 } from "./percent02.svg";
import { ReactComponent as percent03 } from "./percent03.svg";
import { ReactComponent as perspective01 } from "./perspective01.svg";
import { ReactComponent as perspective02 } from "./perspective02.svg";
import { ReactComponent as phone } from "./phone.svg";
import { ReactComponent as phone01 } from "./phone01.svg";
import { ReactComponent as phone02 } from "./phone02.svg";
import { ReactComponent as phoneCall01 } from "./phoneCall01.svg";
import { ReactComponent as phoneCall02 } from "./phoneCall02.svg";
import { ReactComponent as phoneHangUp } from "./phoneHangUp.svg";
import { ReactComponent as phoneIncoming01 } from "./phoneIncoming01.svg";
import { ReactComponent as phoneIncoming02 } from "./phoneIncoming02.svg";
import { ReactComponent as phoneOutgoing01 } from "./phoneOutgoing01.svg";
import { ReactComponent as phoneOutgoing02 } from "./phoneOutgoing02.svg";
import { ReactComponent as phonePause } from "./phonePause.svg";
import { ReactComponent as phonePlus } from "./phonePlus.svg";
import { ReactComponent as phoneX } from "./phoneX.svg";
import { ReactComponent as pieChart01 } from "./pieChart01.svg";
import { ReactComponent as pieChart02 } from "./pieChart02.svg";
import { ReactComponent as pieChart03 } from "./pieChart03.svg";
import { ReactComponent as pieChart04 } from "./pieChart04.svg";
import { ReactComponent as piggyBank01 } from "./piggyBank01.svg";
import { ReactComponent as piggyBank02 } from "./piggyBank02.svg";
import { ReactComponent as pilcrow01 } from "./pilcrow01.svg";
import { ReactComponent as pilcrow02 } from "./pilcrow02.svg";
import { ReactComponent as pilcrowSquare } from "./pilcrowSquare.svg";
import { ReactComponent as pin01 } from "./pin01.svg";
import { ReactComponent as pin02 } from "./pin02.svg";
import { ReactComponent as placeholder } from "./placeholder.svg";
import { ReactComponent as plane } from "./plane.svg";
import { ReactComponent as play } from "./play.svg";
import { ReactComponent as playCircle } from "./playCircle.svg";
import { ReactComponent as playSquare } from "./playSquare.svg";
import { ReactComponent as plus } from "./plus.svg";
import { ReactComponent as plusCircle } from "./plusCircle.svg";
import { ReactComponent as plusSquare } from "./plusSquare.svg";
import { ReactComponent as podcast } from "./podcast.svg";
import { ReactComponent as power01 } from "./power01.svg";
import { ReactComponent as power02 } from "./power02.svg";
import { ReactComponent as power03 } from "./power03.svg";
import { ReactComponent as presentationChart01 } from "./presentationChart01.svg";
import { ReactComponent as presentationChart02 } from "./presentationChart02.svg";
import { ReactComponent as presentationChart03 } from "./presentationChart03.svg";
import { ReactComponent as printer } from "./printer.svg";
import { ReactComponent as puzzlePiece01 } from "./puzzlePiece01.svg";
import { ReactComponent as puzzlePiece02 } from "./puzzlePiece02.svg";
import { ReactComponent as qrCode01 } from "./qrCode01.svg";
import { ReactComponent as qrCode02 } from "./qrCode02.svg";
import { ReactComponent as receipt } from "./receipt.svg";
import { ReactComponent as receiptCheck } from "./receiptCheck.svg";
import { ReactComponent as recording01 } from "./recording01.svg";
import { ReactComponent as recording02 } from "./recording02.svg";
import { ReactComponent as recording03 } from "./recording03.svg";
import { ReactComponent as reflect01 } from "./reflect01.svg";
import { ReactComponent as reflect02 } from "./reflect02.svg";
import { ReactComponent as refreshCcw01 } from "./refreshCcw01.svg";
import { ReactComponent as refreshCcw02 } from "./refreshCcw02.svg";
import { ReactComponent as refreshCcw03 } from "./refreshCcw03.svg";
import { ReactComponent as refreshCcw04 } from "./refreshCcw04.svg";
import { ReactComponent as refreshCcw05 } from "./refreshCcw05.svg";
import { ReactComponent as refreshCw01 } from "./refreshCw01.svg";
import { ReactComponent as refreshCw02 } from "./refreshCw02.svg";
import { ReactComponent as refreshCw03 } from "./refreshCw03.svg";
import { ReactComponent as refreshCw04 } from "./refreshCw04.svg";
import { ReactComponent as refreshCw05 } from "./refreshCw05.svg";
import { ReactComponent as repeat01 } from "./repeat01.svg";
import { ReactComponent as repeat02 } from "./repeat02.svg";
import { ReactComponent as repeat03 } from "./repeat03.svg";
import { ReactComponent as repeat04 } from "./repeat04.svg";
import { ReactComponent as reverseLeft } from "./reverseLeft.svg";
import { ReactComponent as reverseRight } from "./reverseRight.svg";
import { ReactComponent as rightIndent01 } from "./rightIndent01.svg";
import { ReactComponent as rightIndent02 } from "./rightIndent02.svg";
import { ReactComponent as rocket01 } from "./rocket01.svg";
import { ReactComponent as rocket02 } from "./rocket02.svg";
import { ReactComponent as rollerBrush } from "./rollerBrush.svg";
import { ReactComponent as route } from "./route.svg";
import { ReactComponent as rows01 } from "./rows01.svg";
import { ReactComponent as rows02 } from "./rows02.svg";
import { ReactComponent as rows03 } from "./rows03.svg";
import { ReactComponent as rss01 } from "./rss01.svg";
import { ReactComponent as rss02 } from "./rss02.svg";
import { ReactComponent as ruler } from "./ruler.svg";
import { ReactComponent as safe } from "./safe.svg";
import { ReactComponent as sale01 } from "./sale01.svg";
import { ReactComponent as sale02 } from "./sale02.svg";
import { ReactComponent as sale03 } from "./sale03.svg";
import { ReactComponent as sale04 } from "./sale04.svg";
import { ReactComponent as save01 } from "./save01.svg";
import { ReactComponent as save02 } from "./save02.svg";
import { ReactComponent as save03 } from "./save03.svg";
import { ReactComponent as scale01 } from "./scale01.svg";
import { ReactComponent as scale02 } from "./scale02.svg";
import { ReactComponent as scale03 } from "./scale03.svg";
import { ReactComponent as scales01 } from "./scales01.svg";
import { ReactComponent as scales02 } from "./scales02.svg";
import { ReactComponent as scan } from "./scan.svg";
import { ReactComponent as scissors01 } from "./scissors01.svg";
import { ReactComponent as scissors02 } from "./scissors02.svg";
import { ReactComponent as scissorsCut01 } from "./scissorsCut01.svg";
import { ReactComponent as scissorsCut02 } from "./scissorsCut02.svg";
import { ReactComponent as searchLg } from "./searchLg.svg";
import { ReactComponent as searchMd } from "./searchMd.svg";
import { ReactComponent as searchRefraction } from "./searchRefraction.svg";
import { ReactComponent as searchSm } from "./searchSm.svg";
import { ReactComponent as send01 } from "./send01.svg";
import { ReactComponent as send02 } from "./send02.svg";
import { ReactComponent as send03 } from "./send03.svg";
import { ReactComponent as server01 } from "./server01.svg";
import { ReactComponent as server02 } from "./server02.svg";
import { ReactComponent as server03 } from "./server03.svg";
import { ReactComponent as server04 } from "./server04.svg";
import { ReactComponent as server05 } from "./server05.svg";
import { ReactComponent as server06 } from "./server06.svg";
import { ReactComponent as settings01 } from "./settings01.svg";
import { ReactComponent as settings02 } from "./settings02 1.svg";
import { ReactComponent as settings03 } from "./settings03.svg";
import { ReactComponent as settings04 } from "./settings04.svg";
import { ReactComponent as share01 } from "./share01.svg";
import { ReactComponent as share02 } from "./share02.svg";
import { ReactComponent as share03 } from "./share03.svg";
import { ReactComponent as share04 } from "./share04.svg";
import { ReactComponent as share05 } from "./share05.svg";
import { ReactComponent as share06 } from "./share06.svg";
import { ReactComponent as share07 } from "./share07.svg";
import { ReactComponent as shield01 } from "./shield01.svg";
import { ReactComponent as shield02 } from "./shield02.svg";
import { ReactComponent as shield03 } from "./shield03.svg";
import { ReactComponent as shieldDollar } from "./shieldDollar.svg";
import { ReactComponent as shieldOff } from "./shieldOff.svg";
import { ReactComponent as shieldPlus } from "./shieldPlus.svg";
import { ReactComponent as shieldTick } from "./shieldTick.svg";
import { ReactComponent as shieldZap } from "./shieldZap.svg";
import { ReactComponent as shoppingBag01 } from "./shoppingBag01.svg";
import { ReactComponent as shoppingBag02 } from "./shoppingBag02.svg";
import { ReactComponent as shoppingBag03 } from "./shoppingBag03.svg";
import { ReactComponent as shoppingCart01 } from "./shoppingCart01.svg";
import { ReactComponent as shoppingCart02 } from "./shoppingCart02.svg";
import { ReactComponent as shoppingCart03 } from "./shoppingCart03.svg";
import { ReactComponent as shuffle01 } from "./shuffle01.svg";
import { ReactComponent as shuffle02 } from "./shuffle02.svg";
import { ReactComponent as signal01 } from "./signal01.svg";
import { ReactComponent as signal02 } from "./signal02.svg";
import { ReactComponent as signal03 } from "./signal03.svg";
import { ReactComponent as simcard } from "./simcard.svg";
import { ReactComponent as skew } from "./skew.svg";
import { ReactComponent as skipBack } from "./skipBack.svg";
import { ReactComponent as skipForward } from "./skipForward.svg";
import { ReactComponent as slashCircle01 } from "./slashCircle01.svg";
import { ReactComponent as slashCircle02 } from "./slashCircle02.svg";
import { ReactComponent as slashDivider } from "./slashDivider.svg";
import { ReactComponent as slashOctagon } from "./slashOctagon.svg";
import { ReactComponent as sliders01 } from "./sliders01.svg";
import { ReactComponent as sliders02 } from "./sliders02.svg";
import { ReactComponent as sliders03 } from "./sliders03.svg";
import { ReactComponent as sliders04 } from "./sliders04.svg";
import { ReactComponent as snowflake01 } from "./snowflake01.svg";
import { ReactComponent as snowflake02 } from "./snowflake02.svg";
import { ReactComponent as spacingHeight01 } from "./spacingHeight01.svg";
import { ReactComponent as spacingHeight02 } from "./spacingHeight02.svg";
import { ReactComponent as spacingWidth01 } from "./spacingWidth01.svg";
import { ReactComponent as spacingWidth02 } from "./spacingWidth02.svg";
import { ReactComponent as speaker01 } from "./speaker01.svg";
import { ReactComponent as speaker02 } from "./speaker02.svg";
import { ReactComponent as speaker03 } from "./speaker03.svg";
import { ReactComponent as speedometer01 } from "./speedometer01.svg";
import { ReactComponent as speedometer02 } from "./speedometer02.svg";
import { ReactComponent as speedometer03 } from "./speedometer03.svg";
import { ReactComponent as speedometer04 } from "./speedometer04.svg";
import { ReactComponent as square } from "./square.svg";
import { ReactComponent as stand } from "./stand.svg";
import { ReactComponent as star01 } from "./star01.svg";
import { ReactComponent as star02 } from "./star02.svg";
import { ReactComponent as star03 } from "./star03.svg";
import { ReactComponent as star04 } from "./star04.svg";
import { ReactComponent as star05 } from "./star05.svg";
import { ReactComponent as star06 } from "./star06.svg";
import { ReactComponent as star07 } from "./star07.svg";
import { ReactComponent as stars01 } from "./stars01.svg";
import { ReactComponent as stars02 } from "./stars02.svg";
import { ReactComponent as stars03 } from "./stars03.svg";
import { ReactComponent as stickerCircle } from "./stickerCircle.svg";
import { ReactComponent as stickerSquare } from "./stickerSquare.svg";
import { ReactComponent as stop } from "./stop.svg";
import { ReactComponent as stopCircle } from "./stopCircle.svg";
import { ReactComponent as stopSquare } from "./stopSquare.svg";
import { ReactComponent as strikethrough01 } from "./strikethrough01.svg";
import { ReactComponent as strikethrough02 } from "./strikethrough02.svg";
import { ReactComponent as strikethroughSquare } from "./strikethroughSquare.svg";
import { ReactComponent as subscript } from "./subscript.svg";
import { ReactComponent as sun } from "./sun.svg";
import { ReactComponent as sunSetting01 } from "./sunSetting01.svg";
import { ReactComponent as sunSetting02 } from "./sunSetting02.svg";
import { ReactComponent as sunSetting03 } from "./sunSetting03.svg";
import { ReactComponent as sunrise } from "./sunrise.svg";
import { ReactComponent as sunset } from "./sunset.svg";
import { ReactComponent as switchHorizontal01 } from "./switchHorizontal01.svg";
import { ReactComponent as switchHorizontal02 } from "./switchHorizontal02.svg";
import { ReactComponent as switchVertical01 } from "./switchVertical01.svg";
import { ReactComponent as switchVertical02 } from "./switchVertical02.svg";
import { ReactComponent as table } from "./table.svg";
import { ReactComponent as tablet01 } from "./tablet01.svg";
import { ReactComponent as tablet02 } from "./tablet02.svg";
import { ReactComponent as tag01 } from "./tag01.svg";
import { ReactComponent as tag02 } from "./tag02.svg";
import { ReactComponent as tag03 } from "./tag03.svg";
import { ReactComponent as target01 } from "./target01.svg";
import { ReactComponent as target02 } from "./target02.svg";
import { ReactComponent as target03 } from "./target03.svg";
import { ReactComponent as target04 } from "./target04.svg";
import { ReactComponent as target05 } from "./target05.svg";
import { ReactComponent as telescope } from "./telescope.svg";
import { ReactComponent as terminal } from "./terminal.svg";
import { ReactComponent as terminalBrowser } from "./terminalBrowser.svg";
import { ReactComponent as terminalCircle } from "./terminalCircle.svg";
import { ReactComponent as terminalSquare } from "./terminalSquare.svg";
import { ReactComponent as textInput } from "./textInput.svg";
import { ReactComponent as thermometer01 } from "./thermometer01.svg";
import { ReactComponent as thermometer02 } from "./thermometer02.svg";
import { ReactComponent as thermometer03 } from "./thermometer03.svg";
import { ReactComponent as thermometerCold } from "./thermometerCold.svg";
import { ReactComponent as thermometerWarm } from "./thermometerWarm.svg";
import { ReactComponent as thumbsDown } from "./thumbsDown.svg";
import { ReactComponent as thumbsUp } from "./thumbsUp.svg";
import { ReactComponent as ticket01 } from "./ticket01.svg";
import { ReactComponent as ticket02 } from "./ticket02.svg";
import { ReactComponent as toggle01Left } from "./toggle01Left.svg";
import { ReactComponent as toggle01Right } from "./toggle01Right.svg";
import { ReactComponent as toggle02Left } from "./toggle02Left.svg";
import { ReactComponent as toggle02Right } from "./toggle02Right.svg";
import { ReactComponent as toggle03Left } from "./toggle03Left.svg";
import { ReactComponent as toggle03Right } from "./toggle03Right.svg";
import { ReactComponent as tool01 } from "./tool01.svg";
import { ReactComponent as tool02 } from "./tool02.svg";
import { ReactComponent as train } from "./train.svg";
import { ReactComponent as tram } from "./tram.svg";
import { ReactComponent as transform } from "./transform.svg";
import { ReactComponent as translate01 } from "./translate01.svg";
import { ReactComponent as translate02 } from "./translate02.svg";
import { ReactComponent as trash01 } from "./trash01.svg";
import { ReactComponent as trash02 } from "./trash02.svg";
import { ReactComponent as trash03 } from "./trash03.svg";
import { ReactComponent as trash04 } from "./trash04.svg";
import { ReactComponent as trendDown01 } from "./trendDown01.svg";
import { ReactComponent as trendDown02 } from "./trendDown02.svg";
import { ReactComponent as trendUp01 } from "./trendUp01.svg";
import { ReactComponent as trendUp02 } from "./trendUp02.svg";
import { ReactComponent as triangle } from "./triangle.svg";
import { ReactComponent as trophy01 } from "./trophy01.svg";
import { ReactComponent as trophy02 } from "./trophy02.svg";
import { ReactComponent as truck01 } from "./truck01.svg";
import { ReactComponent as truck02 } from "./truck02.svg";
import { ReactComponent as tv01 } from "./tv01.svg";
import { ReactComponent as tv02 } from "./tv02.svg";
import { ReactComponent as tv03 } from "./tv03.svg";
import { ReactComponent as type01 } from "./type01.svg";
import { ReactComponent as type02 } from "./type02.svg";
import { ReactComponent as typeSquare } from "./typeSquare.svg";
import { ReactComponent as typeStrikethrough01 } from "./typeStrikethrough01.svg";
import { ReactComponent as typeStrikethrough02 } from "./typeStrikethrough02.svg";
import { ReactComponent as umbrella01 } from "./umbrella01.svg";
import { ReactComponent as umbrella02 } from "./umbrella02.svg";
import { ReactComponent as umbrella03 } from "./umbrella03.svg";
import { ReactComponent as underline01 } from "./underline01.svg";
import { ReactComponent as underline02 } from "./underline02.svg";
import { ReactComponent as underlineSquare } from "./underlineSquare.svg";
import { ReactComponent as upload01 } from "./upload01.svg";
import { ReactComponent as upload02 } from "./upload02.svg";
import { ReactComponent as upload03 } from "./upload03.svg";
import { ReactComponent as upload04 } from "./upload04.svg";
import { ReactComponent as uploadCloud01 } from "./uploadCloud01.svg";
import { ReactComponent as uploadCloud02 } from "./uploadCloud02.svg";
import { ReactComponent as usbFlashDrive } from "./usbFlashDrive.svg";
import { ReactComponent as user01 } from "./user01.svg";
import { ReactComponent as user02 } from "./user02.svg";
import { ReactComponent as user03 } from "./user03.svg";
import { ReactComponent as userCheck01 } from "./userCheck01.svg";
import { ReactComponent as userCheck02 } from "./userCheck02.svg";
import { ReactComponent as userCircle } from "./userCircle.svg";
import { ReactComponent as userDown01 } from "./userDown01.svg";
import { ReactComponent as userDown02 } from "./userDown02.svg";
import { ReactComponent as userEdit } from "./userEdit.svg";
import { ReactComponent as userLeft01 } from "./userLeft01.svg";
import { ReactComponent as userLeft02 } from "./userLeft02.svg";
import { ReactComponent as userMinus01 } from "./userMinus01.svg";
import { ReactComponent as userMinus02 } from "./userMinus02.svg";
import { ReactComponent as userPlus01 } from "./userPlus01.svg";
import { ReactComponent as userPlus02 } from "./userPlus02.svg";
import { ReactComponent as userRight01 } from "./userRight01.svg";
import { ReactComponent as userRight02 } from "./userRight02.svg";
import { ReactComponent as userSquare } from "./userSquare.svg";
import { ReactComponent as userUp01 } from "./userUp01.svg";
import { ReactComponent as userUp02 } from "./userUp02.svg";
import { ReactComponent as userX01 } from "./userX01.svg";
import { ReactComponent as userX02 } from "./userX02.svg";
import { ReactComponent as users01 } from "./users01.svg";
import { ReactComponent as users02 } from "./users02.svg";
import { ReactComponent as users03 } from "./users03.svg";
import { ReactComponent as usersCheck } from "./usersCheck.svg";
import { ReactComponent as usersDown } from "./usersDown.svg";
import { ReactComponent as usersEdit } from "./usersEdit.svg";
import { ReactComponent as usersLeft } from "./usersLeft.svg";
import { ReactComponent as usersMinus } from "./usersMinus.svg";
import { ReactComponent as usersPlus } from "./usersPlus.svg";
import { ReactComponent as usersRight } from "./usersRight.svg";
import { ReactComponent as usersUp } from "./usersUp.svg";
import { ReactComponent as usersX } from "./usersX.svg";
import { ReactComponent as variable } from "./variable.svg";
import { ReactComponent as videoRecorder } from "./videoRecorder.svg";
import { ReactComponent as videoRecorderOff } from "./videoRecorderOff.svg";
import { ReactComponent as virus } from "./virus.svg";
import { ReactComponent as voicemail } from "./voicemail.svg";
import { ReactComponent as volumeMax } from "./volumeMax.svg";
import { ReactComponent as volumeMin } from "./volumeMin.svg";
import { ReactComponent as volumeMinus } from "./volumeMinus.svg";
import { ReactComponent as volumePlus } from "./volumePlus.svg";
import { ReactComponent as volumeX } from "./volumeX.svg";
import { ReactComponent as wallet01 } from "./wallet01.svg";
import { ReactComponent as wallet02 } from "./wallet02.svg";
import { ReactComponent as wallet03 } from "./wallet03.svg";
import { ReactComponent as wallet04 } from "./wallet04.svg";
import { ReactComponent as wallet05 } from "./wallet05.svg";
import { ReactComponent as watchCircle } from "./watchCircle.svg";
import { ReactComponent as watchSquare } from "./watchSquare.svg";
import { ReactComponent as waves } from "./waves.svg";
import { ReactComponent as webcam01 } from "./webcam01.svg";
import { ReactComponent as webcam02 } from "./webcam02.svg";
import { ReactComponent as wifi } from "./wifi.svg";
import { ReactComponent as wifiOff } from "./wifiOff.svg";
import { ReactComponent as wind01 } from "./wind01.svg";
import { ReactComponent as wind02 } from "./wind02.svg";
import { ReactComponent as wind03 } from "./wind03.svg";
import { ReactComponent as x } from "./x.svg";
import { ReactComponent as xCircle } from "./xCircle.svg";
import { ReactComponent as xClose } from "./xClose.svg";
import { ReactComponent as xSquare } from "./xSquare.svg";
import { ReactComponent as youtube } from "./youtube.svg";
import { ReactComponent as zap } from "./zap.svg";
import { ReactComponent as zapCircle } from "./zapCircle.svg";
import { ReactComponent as zapFast } from "./zapFast.svg";
import { ReactComponent as zapOff } from "./zapOff.svg";
import { ReactComponent as zapSquare } from "./zapSquare.svg";
import { ReactComponent as zoomIn } from "./zoomIn.svg";
import { ReactComponent as zoomOut } from "./zoomOut.svg";
import { ReactComponent as microsoft } from "./microsoft.svg";
import { ReactComponent as salesforce } from "./salesforce.svg";
import { ReactComponent as aws } from "./aws.svg";
import { ReactComponent as gcp } from "./gcp.svg";
import { ReactComponent as stripe } from "./stripe.svg";
import { ReactComponent as workday } from "./workday.svg";
import { ReactComponent as netsuite } from "./netsuite.svg";
import { ReactComponent as quickbooks } from "./quickbooks.svg";
const icons = Object.freeze({
  activity,
  activityHeart,
  airplay,
  airpods,
  alarmClock,
  alarmClockCheck,
  alarmClockMinus,
  alarmClockOff,
  alarmClockPlus,
  alertCircle,
  alertHexagon,
  alertOctagon,
  alertSquare,
  alertTriangle,
  alignBottom01,
  alignBottom02,
  alignCenter,
  alignHorizontalCentre01,
  alignHorizontalCentre02,
  alignJustify,
  alignLeft,
  alignLeft01,
  alignLeft02,
  alignRight,
  alignRight01,
  alignRight02,
  alignTop01,
  alignTop02,
  alignVerticalCenter01,
  alignVerticalCenter02,
  anchor,
  annotation,
  annotationAlert,
  annotationCheck,
  annotationDots,
  annotationHeart,
  annotationInfo,
  annotationPlus,
  annotationQuestion,
  annotationX,
  announcement01,
  announcement02,
  announcement03,
  archive,
  arrowBlockDown,
  arrowBlockLeft,
  arrowBlockRight,
  arrowBlockUp,
  arrowCircleBrokenDown,
  arrowCircleBrokenDownLeft,
  arrowCircleBrokenDownRight,
  arrowCircleBrokenLeft,
  arrowCircleBrokenRight,
  arrowCircleBrokenUp,
  arrowCircleBrokenUpLeft,
  arrowCircleBrokenUpRight,
  arrowCircleDown,
  arrowCircleDownLeft,
  arrowCircleDownRight,
  arrowCircleLeft,
  arrowCircleRight,
  arrowCircleUp,
  arrowCircleUpLeft,
  arrowCircleUpRight,
  arrowDown,
  arrowDownLeft,
  arrowDownRight,
  arrowLeft,
  arrowNarrowDown,
  arrowNarrowDownLeft,
  arrowNarrowDownRight,
  arrowNarrowLeft,
  arrowNarrowRight,
  arrowNarrowUp,
  arrowNarrowUpLeft,
  arrowNarrowUpRight,
  arrowRight,
  arrowSquareDown,
  arrowSquareDownLeft,
  arrowSquareDownRight,
  arrowSquareLeft,
  arrowSquareRight,
  arrowSquareUp,
  arrowSquareUpLeft,
  arrowSquareUpRight,
  arrowUp,
  arrowUpLeft,
  arrowUpRight,
  arrowsDown,
  arrowsLeft,
  arrowsRight,
  arrowsTriangle,
  arrowsUp,
  asterisk01,
  asterisk02,
  atSign,
  atom01,
  atom02,
  attachment01,
  attachment02,
  award01,
  award02,
  award03,
  award04,
  award05,
  aws,
  backpack,
  bank,
  bankNote01,
  bankNote02,
  bankNote03,
  barChart01,
  barChart02,
  barChart03,
  barChart04,
  barChart05,
  barChart06,
  barChart07,
  barChart08,
  barChart09,
  barChart10,
  barChart11,
  barChart12,
  barChartCircle01,
  barChartCircle02,
  barChartCircle03,
  barChartSquare01,
  barChartSquare02,
  barChartSquare03,
  barChartSquareDown,
  barChartSquareMinus,
  barChartSquarePlus,
  barChartSquareUp,
  barLineChart,
  batteryCharging01,
  batteryCharging02,
  batteryEmpty,
  batteryFull,
  batteryLow,
  batteryMid,
  beaker01,
  beaker02,
  bell01,
  bell02,
  bell03,
  bell04,
  bellMinus,
  bellOff01,
  bellOff02,
  bellOff03,
  bellPlus,
  bellRinging01,
  bellRinging02,
  bellRinging03,
  bellRinging04,
  bezierCurve01,
  bezierCurve02,
  bezierCurve03,
  bluetoothConnect,
  bluetoothOff,
  bluetoothOn,
  bluetoothSignal,
  bold01,
  bold02,
  boldSquare,
  bookClosed,
  bookOpen01,
  bookOpen02,
  bookmark,
  bookmarkAdd,
  bookmarkCheck,
  bookmarkMinus,
  bookmarkX,
  box,
  brackets,
  bracketsCheck,
  bracketsEllipses,
  bracketsMinus,
  bracketsPlus,
  bracketsSlash,
  bracketsX,
  briefcase01,
  briefcase02,
  browser,
  brush01,
  brush02,
  brush03,
  building01,
  building02,
  building03,
  building04,
  building05,
  building06,
  building07,
  building08,
  bus,
  calculator,
  calendar,
  calendarCheck01,
  calendarCheck02,
  calendarDate,
  calendarHeart01,
  calendarHeart02,
  calendarMinus01,
  calendarMinus02,
  calendarPlus01,
  calendarPlus02,
  camera01,
  camera02,
  camera03,
  cameraLens,
  cameraOff,
  cameraPlus,
  car01,
  car02,
  certificate01,
  certificate02,
  chartBreakoutCircle,
  chartBreakoutSquare,
  check,
  checkCircle,
  checkCircleBroken,
  checkDone01,
  checkDone02,
  checkHeart,
  checkSquare,
  checkSquareBroken,
  checkVerified01,
  checkVerified02,
  checkVerified03,
  chevronDown,
  chevronDownDouble,
  chevronLeft,
  chevronLeftDouble,
  chevronRight,
  chevronRightDouble,
  chevronSelectorHorizontal,
  chevronSelectorVertical,
  chevronUp,
  chevronUpDouble,
  chromeCast,
  circle,
  circleCut,
  clapperboard,
  clipboard,
  clipboardAttachment,
  clipboardCheck,
  clipboardDownload,
  clipboardMinus,
  clipboardPlus,
  clipboardX,
  clock,
  clockCheck,
  clockFastForward,
  clockPlus,
  clockRefresh,
  clockRewind,
  clockSnooze,
  clockStopwatch,
  cloud01,
  cloud02,
  cloud03,
  cloudBlank01,
  cloudBlank02,
  cloudLightning,
  cloudMoon,
  cloudOff,
  cloudRaining01,
  cloudRaining02,
  cloudRaining03,
  cloudRaining04,
  cloudRaining05,
  cloudRaining06,
  cloudSnowing01,
  cloudSnowing02,
  cloudSun01,
  cloudSun02,
  cloudSun03,
  code01,
  code02,
  codeBrowser,
  codeCircle01,
  codeCircle02,
  codeCircle03,
  codeSnippet01,
  codeSnippet02,
  codeSquare01,
  codeSquare02,
  codepen,
  coins01,
  coins02,
  coins03,
  coins04,
  coinsHand,
  coinsStacked01,
  coinsStacked02,
  coinsStacked03,
  coinsStacked04,
  coinsSwap01,
  coinsSwap02,
  colors,
  columns01,
  columns02,
  columns03,
  command,
  compass,
  compass01,
  compass02,
  compass03,
  container,
  contrast01,
  contrast02,
  contrast03,
  copy01,
  copy02,
  copy03,
  copy04,
  copy05,
  copy06,
  copy07,
  cornerDownLeft,
  cornerDownRight,
  cornerLeftDown,
  cornerLeftUp,
  cornerRightDown,
  cornerRightUp,
  cornerUpLeft,
  cornerUpRight,
  cpuChip01,
  cpuChip02,
  creditCard01,
  creditCard02,
  creditCardCheck,
  creditCardDown,
  creditCardDownload,
  creditCardEdit,
  creditCardLock,
  creditCardMinus,
  creditCardPlus,
  creditCardRefresh,
  creditCardSearch,
  creditCardShield,
  creditCardUp,
  creditCardUpload,
  creditCardX,
  crop01,
  crop02,
  cryptocurrency01,
  cryptocurrency02,
  cryptocurrency03,
  cryptocurrency04,
  cube01,
  cube02,
  cube03,
  cube04,
  cubeOutline,
  currencyBitcoin,
  currencyBitcoinCircle,
  currencyDollar,
  currencyDollarCircle,
  currencyEthereum,
  currencyEthereumCircle,
  currencyEuro,
  currencyEuroCircle,
  currencyPound,
  currencyPoundCircle,
  currencyRuble,
  currencyRubleCircle,
  currencyRupee,
  currencyRupeeCircle,
  currencyYen,
  currencyYenCircle,
  cursor01,
  cursor02,
  cursor03,
  cursor04,
  cursorBox,
  cursorClick01,
  cursorClick02,
  data,
  database01,
  database02,
  database03,
  dataflow01,
  dataflow02,
  dataflow03,
  dataflow04,
  delete: deleteIcon,
  diamond01,
  diamond02,
  dice1,
  dice2,
  dice3,
  dice4,
  dice5,
  dice6,
  disc01,
  disc02,
  distributeSpacingHorizontal,
  distributeSpacingVertical,
  divide01,
  divide02,
  divide03,
  divider,
  dotpoints01,
  dotpoints02,
  dotsGrid,
  dotsHorizontal,
  dotsVertical,
  download01,
  download02,
  download03,
  download04,
  downloadCloud01,
  downloadCloud02,
  drop,
  droplets01,
  droplets02,
  droplets03,
  dropper,
  edit01,
  edit02,
  edit03,
  edit04,
  edit05,
  equal,
  equalNot,
  eraser,
  expand01,
  expand02,
  expand03,
  expand04,
  expand05,
  expand06,
  eye,
  eyeOff,
  faceContent,
  faceFrown,
  faceHappy,
  faceId,
  faceIdSquare,
  faceNeutral,
  faceSad,
  faceSmile,
  faceWink,
  fastBackward,
  fastForward,
  feather,
  figma,
  file01,
  file02,
  file03,
  file04,
  file05,
  file06,
  file07,
  fileAttachment01,
  fileAttachment02,
  fileAttachment03,
  fileAttachment04,
  fileAttachment05,
  fileCheck01,
  fileCheck02,
  fileCheck03,
  fileCode01,
  fileCode02,
  fileDownload01,
  fileDownload02,
  fileDownload03,
  fileHeart01,
  fileHeart02,
  fileHeart03,
  fileLock01,
  fileLock02,
  fileLock03,
  fileMinus01,
  fileMinus02,
  fileMinus03,
  filePlus01,
  filePlus02,
  filePlus03,
  fileQuestion01,
  fileQuestion02,
  fileQuestion03,
  fileSearch01,
  fileSearch02,
  fileSearch03,
  fileShield01,
  fileShield02,
  fileShield03,
  fileX01,
  fileX02,
  fileX03,
  film01,
  film02,
  film03,
  filterFunnel01,
  filterFunnel02,
  filterLines,
  fingerprint01,
  fingerprint02,
  fingerprint03,
  fingerprint04,
  flag01,
  flag02,
  flag03,
  flag04,
  flag05,
  flag06,
  flash,
  flashOff,
  flexAlignBottom,
  flexAlignLeft,
  flexAlignRight,
  flexAlignTop,
  flipBackward,
  flipForward,
  folder,
  folderCheck,
  folderClosed,
  folderCode,
  folderDownload,
  folderLock,
  folderMinus,
  folderPlus,
  folderQuestion,
  folderSearch,
  folderShield,
  folderX,
  framer,
  gamingPad01,
  gamingPad02,
  gcp,
  gift01,
  gift02,
  gitBranch01,
  gitBranch02,
  gitCommit,
  gitMerge,
  gitPullRequest,
  glasses01,
  glasses02,
  globe01,
  globe02,
  globe03,
  globe04,
  globe05,
  globe06,
  globeSlated01,
  globeSlated02,
  googleChrome,
  graduationHat01,
  graduationHat02,
  grid01,
  grid02,
  grid03,
  gridDotsBlank,
  gridDotsBottom,
  gridDotsHorizontalCenter,
  gridDotsLeft,
  gridDotsOuter,
  gridDotsRight,
  gridDotsTop,
  gridDotsVerticalCenter,
  hand,
  hardDrive,
  hash01,
  hash02,
  heading01,
  heading02,
  headingSquare,
  headphones01,
  headphones02,
  heart,
  heartCircle,
  heartHand,
  heartHexagon,
  heartOctagon,
  heartRounded,
  heartSquare,
  hearts,
  helpCircle,
  helpHexagon,
  helpOctagon,
  helpSquare,
  hexagon01,
  hexagon02,
  home01,
  home02,
  home03,
  home04,
  home05,
  homeLine,
  homeSmile,
  horizontalBarChart01,
  horizontalBarChart02,
  horizontalBarChart03,
  hourglass01,
  hourglass02,
  hourglass03,
  hurricane01,
  hurricane02,
  hurricane03,
  image01,
  image02,
  image03,
  image04,
  image05,
  imageCheck,
  imageDown,
  imageIndentLeft,
  imageIndentRight,
  imageLeft,
  imagePlus,
  imageRight,
  imageUp,
  imageUser,
  imageUserCheck,
  imageUserDown,
  imageUserLeft,
  imageUserPlus,
  imageUserRight,
  imageUserUp,
  imageUserX,
  imageX,
  inbox01,
  inbox02,
  infinity,
  infoCircle,
  infoHexagon,
  infoOctagon,
  infoSquare,
  intersectCircle,
  intersectSquare,
  italic01,
  italic02,
  italicSquare,
  key01,
  key02,
  keyboard01,
  keyboard02,
  laptop01,
  laptop02,
  layerSingle,
  layersThree01,
  layersThree02,
  layersTwo01,
  layersTwo02,
  layoutAlt01,
  layoutAlt02,
  layoutAlt03,
  layoutAlt04,
  layoutBottom,
  layoutGrid01,
  layoutGrid02,
  layoutLeft,
  layoutRight,
  layoutTop,
  leftIndent01,
  leftIndent02,
  letterSpacing01,
  letterSpacing02,
  lifeBuoy01,
  lifeBuoy02,
  lightbulb01,
  lightbulb02,
  lightbulb03,
  lightbulb04,
  lightbulb05,
  lightning01,
  lightning02,
  lineChartDown01,
  lineChartDown02,
  lineChartDown03,
  lineChartDown04,
  lineChartDown05,
  lineChartUp01,
  lineChartUp02,
  lineChartUp03,
  lineChartUp04,
  lineChartUp05,
  lineHeight,
  link01,
  link02,
  link03,
  link04,
  link05,
  linkBroken01,
  linkBroken02,
  linkExternal01,
  linkExternal02,
  list,
  loading01,
  loading02,
  loading03,
  lock01,
  lock02,
  lock03,
  lock04,
  lockKeyholeCircle,
  lockKeyholeSquare,
  lockUnlocked01,
  lockUnlocked02,
  lockUnlocked03,
  lockUnlocked04,
  logIn01,
  logIn02,
  logIn03,
  logIn04,
  logOut01,
  logOut02,
  logOut03,
  logOut04,
  luggage01,
  luggage02,
  luggage03,
  magicWand01,
  magicWand02,
  mail01,
  mail02,
  mail03,
  mail04,
  mail05,
  map01,
  map02,
  mark,
  markerPin01,
  markerPin02,
  markerPin03,
  markerPin04,
  markerPin05,
  markerPin06,
  maximize01,
  maximize02,
  medicalCircle,
  medicalCross,
  medicalSquare,
  menu01,
  menu02,
  menu03,
  menu04,
  menu05,
  messageAlertCircle,
  messageAlertSquare,
  messageChatCircle,
  messageChatSquare,
  messageCheckCircle,
  messageCheckSquare,
  messageCircle01,
  messageCircle02,
  messageDotsCircle,
  messageDotsSquare,
  messageHeartCircle,
  messageHeartSquare,
  messageNotificationCircle,
  messageNotificationSquare,
  messagePlusCircle,
  messagePlusSquare,
  messageQuestionCircle,
  messageQuestionSquare,
  messageSmileCircle,
  messageSmileSquare,
  messageSquare01,
  messageSquare02,
  messageTextCircle01,
  messageTextCircle02,
  messageTextSquare01,
  messageTextSquare02,
  messageXCircle,
  messageXSquare,
  microphone01,
  microphone02,
  microphoneOff01,
  microphoneOff02,
  microscope,
  microsoft,
  minimize01,
  minimize02,
  minus,
  minusCircle,
  minusSquare,
  modem01,
  modem02,
  monitor01,
  monitor02,
  monitor03,
  monitor04,
  monitor05,
  moon01,
  moon02,
  moonEclipse,
  moonStar,
  mouse,
  move,
  musicNote01,
  musicNote02,
  musicNotePlus,
  navigationPointer01,
  navigationPointer02,
  navigationPointerOff01,
  navigationPointerOff02,
  netsuite,
  notificationBox,
  notificationMessage,
  notificationText,
  octagon,
  package: packageIcon,
  packageCheck,
  packageMinus,
  packagePlus,
  packageSearch,
  packageX,
  paint,
  paintPour,
  palette,
  paperclip,
  paragraphSpacing,
  paragraphWrap,
  passcode,
  passcodeLock,
  passport,
  pauseCircle,
  pauseSquare,
  penTool01,
  penTool02,
  penToolMinus,
  penToolPlus,
  pencil01,
  pencil02,
  pencilLine,
  pentagon,
  percent01,
  percent02,
  percent03,
  perspective01,
  perspective02,
  phone,
  phone01,
  phone02,
  phoneCall01,
  phoneCall02,
  phoneHangUp,
  phoneIncoming01,
  phoneIncoming02,
  phoneOutgoing01,
  phoneOutgoing02,
  phonePause,
  phonePlus,
  phoneX,
  pieChart01,
  pieChart02,
  pieChart03,
  pieChart04,
  piggyBank01,
  piggyBank02,
  pilcrow01,
  pilcrow02,
  pilcrowSquare,
  pin01,
  pin02,
  placeholder,
  plane,
  play,
  playCircle,
  playSquare,
  plus,
  plusCircle,
  plusSquare,
  podcast,
  power01,
  power02,
  power03,
  presentationChart01,
  presentationChart02,
  presentationChart03,
  printer,
  puzzlePiece01,
  puzzlePiece02,
  quickbooks,
  qrCode01,
  qrCode02,
  receipt,
  receiptCheck,
  recording01,
  recording02,
  recording03,
  reflect01,
  reflect02,
  refreshCcw01,
  refreshCcw02,
  refreshCcw03,
  refreshCcw04,
  refreshCcw05,
  refreshCw01,
  refreshCw02,
  refreshCw03,
  refreshCw04,
  refreshCw05,
  repeat01,
  repeat02,
  repeat03,
  repeat04,
  reverseLeft,
  reverseRight,
  rightIndent01,
  rightIndent02,
  rocket01,
  rocket02,
  rollerBrush,
  route,
  rows01,
  rows02,
  rows03,
  rss01,
  rss02,
  ruler,
  safe,
  sale01,
  sale02,
  sale03,
  sale04,
  salesforce,
  save01,
  save02,
  save03,
  scale01,
  scale02,
  scale03,
  scales01,
  scales02,
  scan,
  scissors01,
  scissors02,
  scissorsCut01,
  scissorsCut02,
  searchLg,
  searchMd,
  searchRefraction,
  searchSm,
  send01,
  send02,
  send03,
  server01,
  server02,
  server03,
  server04,
  server05,
  server06,
  settings01,
  settings02,
  settings03,
  settings04,
  share01,
  share02,
  share03,
  share04,
  share05,
  share06,
  share07,
  shield01,
  shield02,
  shield03,
  shieldDollar,
  shieldOff,
  shieldPlus,
  shieldTick,
  shieldZap,
  shoppingBag01,
  shoppingBag02,
  shoppingBag03,
  shoppingCart01,
  shoppingCart02,
  shoppingCart03,
  shuffle01,
  shuffle02,
  signal01,
  signal02,
  signal03,
  simcard,
  skew,
  skipBack,
  skipForward,
  slashCircle01,
  slashCircle02,
  slashDivider,
  slashOctagon,
  sliders01,
  sliders02,
  sliders03,
  sliders04,
  snowflake01,
  snowflake02,
  spacingHeight01,
  spacingHeight02,
  spacingWidth01,
  spacingWidth02,
  speaker01,
  speaker02,
  speaker03,
  speedometer01,
  speedometer02,
  speedometer03,
  speedometer04,
  square,
  stand,
  star01,
  star02,
  star03,
  star04,
  star05,
  star06,
  star07,
  stars01,
  stars02,
  stars03,
  stickerCircle,
  stickerSquare,
  stop,
  stopCircle,
  stopSquare,
  strikethrough01,
  strikethrough02,
  strikethroughSquare,
  stripe,
  subscript,
  sun,
  sunSetting01,
  sunSetting02,
  sunSetting03,
  sunrise,
  sunset,
  switchHorizontal01,
  switchHorizontal02,
  switchVertical01,
  switchVertical02,
  table,
  tablet01,
  tablet02,
  tag01,
  tag02,
  tag03,
  target01,
  target02,
  target03,
  target04,
  target05,
  telescope,
  terminal,
  terminalBrowser,
  terminalCircle,
  terminalSquare,
  textInput,
  thermometer01,
  thermometer02,
  thermometer03,
  thermometerCold,
  thermometerWarm,
  thumbsDown,
  thumbsUp,
  ticket01,
  ticket02,
  toggle01Left,
  toggle01Right,
  toggle02Left,
  toggle02Right,
  toggle03Left,
  toggle03Right,
  tool01,
  tool02,
  train,
  tram,
  transform,
  translate01,
  translate02,
  trash01,
  trash02,
  trash03,
  trash04,
  trendDown01,
  trendDown02,
  trendUp01,
  trendUp02,
  triangle,
  trophy01,
  trophy02,
  truck01,
  truck02,
  tv01,
  tv02,
  tv03,
  type01,
  type02,
  typeSquare,
  typeStrikethrough01,
  typeStrikethrough02,
  umbrella01,
  umbrella02,
  umbrella03,
  underline01,
  underline02,
  underlineSquare,
  upload01,
  upload02,
  upload03,
  upload04,
  uploadCloud01,
  uploadCloud02,
  usbFlashDrive,
  user01,
  user02,
  user03,
  userCheck01,
  userCheck02,
  userCircle,
  userDown01,
  userDown02,
  userEdit,
  userLeft01,
  userLeft02,
  userMinus01,
  userMinus02,
  userPlus01,
  userPlus02,
  userRight01,
  userRight02,
  userSquare,
  userUp01,
  userUp02,
  userX01,
  userX02,
  users01,
  users02,
  users03,
  usersCheck,
  usersDown,
  usersEdit,
  usersLeft,
  usersMinus,
  usersPlus,
  usersRight,
  usersUp,
  usersX,
  variable,
  videoRecorder,
  videoRecorderOff,
  virus,
  voicemail,
  volumeMax,
  volumeMin,
  volumeMinus,
  volumePlus,
  volumeX,
  wallet01,
  wallet02,
  wallet03,
  wallet04,
  wallet05,
  watchCircle,
  watchSquare,
  waves,
  webcam01,
  webcam02,
  wifi,
  wifiOff,
  wind01,
  wind02,
  wind03,
  workday,
  x,
  xCircle,
  xClose,
  xSquare,
  youtube,
  zap,
  zapCircle,
  zapFast,
  zapOff,
  zapSquare,
  zoomIn,
  zoomOut,
});

export default icons;
