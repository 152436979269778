import React from "react";
import classNames from "classnames";
import { Icon } from "design-system";
import { DocumentNode } from "graphql";

import { reportToSentry } from "lib/errors/sentry";
import { getUserFacingErrorMessage } from "./errorHandling";
import { GatedButton } from "../../components/GatedButton";

interface Props {
  /**
   * The top-level error message, suggest something like:
   * "We ran into an issue loading this page"
   */
  title: string;
  error: unknown;
  compact?: boolean;

  buttonText?: string;
  buttonAuthDoc?: DocumentNode;
  buttonRoutePath?: string;
}

export const ErrorEmptyState: React.FC<Props> = (props) => {
  const [sentryEventId, setSentryEventId] = React.useState<string>();

  React.useEffect(() => {
    if (props.error) {
      setSentryEventId(reportToSentry(props.error));
    } else {
      setSentryEventId(undefined);
    }
  }, [props.error]);

  const errorMessage = getUserFacingErrorMessage(props.error, "");

  const icon = (
    <div
      className={classNames(
        "flex items-center justify-center bg-error-50 text-center text-error-500",
        {
          "mb-12 h-[80px] w-[80px] rounded-[40px]": !props.compact,
          "h-[40px] w-[40px] rounded-[20px]": props.compact,
        },
      )}
    >
      <Icon
        icon="sadOutline"
        className={classNames({
          "text-[30px]": !props.compact,
          "text-[20px]": props.compact,
        })}
      />
    </div>
  );

  const title = (
    <p
      className={classNames("font-medium", {
        "text-lg": props.compact,
        "text-xl": !props.compact,
      })}
    >
      {props.title}
    </p>
  );

  const text = (
    <>
      <p className="max-w-xl text-sm">
        Don't worry! All of your data is safe, just try refreshing the page. If
        this problem persists, please contact us for support.
      </p>
      {errorMessage ? (
        <p className="font-mono text-xxs text-gray-medium">
          Error: {errorMessage}
        </p>
      ) : null}
      {sentryEventId ? (
        <p className="font-mono text-xxs text-gray-medium">
          Report ID: {sentryEventId}
        </p>
      ) : null}
    </>
  );

  const button = props.buttonText ? (
    <GatedButton
      doc={props.buttonAuthDoc}
      text={props.buttonText}
      theme="primary"
      linkTo={props.buttonRoutePath}
    />
  ) : null;

  return props.compact ? (
    <div className="mx-auto flex flex-row gap-12 p-12">
      <div className="grow-0">{icon}</div>
      <div className="flex grow flex-col gap-4">
        {title}
        {text}
        {button}
      </div>
    </div>
  ) : (
    <div className="mx-auto flex grow flex-col items-center justify-center gap-12 p-24 text-center">
      {icon}
      {title}
      {text}
      {button}
    </div>
  );
};
