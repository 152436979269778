import React, { useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover-next";
import "/src/tenaissance/tenaissance.css";

interface TooltipProps {
  /** Main label content for the Tooltip */
  label?: string | React.ReactNode;
  /** Secondary and de-emphasized content in the Tooltip, underneath the label */
  subLabel?: string | React.ReactNode;
  /** If present, Tooltip will render an arrow pointing to the children */
  children?: React.ReactNode;
}

type TooltipContentProps = Omit<TooltipProps, "children">;

const TooltipContent: React.FC<TooltipContentProps> = ({ label, subLabel }) => (
  <div className="px-lg py-md rounded-md min-h-4xl inline-flex max-w-[360px] flex-col items-start bg-black text-xs font-semibold text-white shadow-lg">
    {label && <span>{label}</span>}
    {subLabel && <div className="font-medium text-gray-200">{subLabel}</div>}
  </div>
);

/**
 * !! PARTIAL IMPLEMENTATION !!
 * Tooltips are used to describe or identify an element. In most scenarios,
 *  tooltips help the user understand the meaning or function of an icon,
 *  or display the alt text of an image.
 */
export const Tooltip: React.FC<TooltipProps> = ({
  label,
  subLabel,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return children ? (
    <Popover
      positions={["top", "left", "right", "bottom"]}
      isOpen={isOpen}
      transform={{ left: 2 }}
      transformMode="relative"
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="#0C111D"
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <TooltipContent label={label} subLabel={subLabel} />
        </ArrowContainer>
      )}
    >
      <span
        onMouseOver={() => setIsOpen(true)}
        onMouseOut={() => setIsOpen(false)}
      >
        {children}
      </span>
    </Popover>
  ) : (
    <TooltipContent label={label} subLabel={subLabel} />
  );
};
